import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableFooter,
    TableCell,
    TableRow,
    Button,
    TablePagination,
} from '@mui/material';
import cls from './LicenseList.module.scss';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const LicenseList = () => {
const [pageNumber, setPageNumber] = useState(0);
const [pageSize, setPageSize] = useState(20);
const [licenseList, setLicenseList] = useState([]);
const [errorMessage, setErrorMessage] = useState('');

useEffect(() => {
    const fetchLicenseData = async () => {
    try {
        const response = await axios.get('https://dev.tsvs.kg:5545/api/lc/list-licenses');
        setLicenseList(response.data);
    } catch (error) {
        setErrorMessage(error.message);
    }
    };
    fetchLicenseData();
}, []);

const handlePageChange = (event, newPage) => {
    setPageNumber(newPage);
};

const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(0);
};

const currentPageData = licenseList.slice(
    pageNumber * pageSize,
    (pageNumber + 1) * pageSize
);

if (licenseList.length === 0) {
    return <div>Нет лицензий</div>;
}

return (
    <TableContainer component={Paper} className={cls.container}>
    <Table>
        <TableHead>
        <TableRow>
            <TableCell width={'5%'} className={cls.header}>
            №
            </TableCell>
            <TableCell align="center" width={'50%'} className={cls.header}>
            Наименование юр. лиц
            </TableCell>
            <TableCell align="center" width={'25%'} className={cls.header}>
            ИНН
            </TableCell>
            <TableCell align="center" width={'20%'} className={cls.header}>
            Действия
            </TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
        {currentPageData.map((license, index) => (
            <TableRow key={license.id}>
            <TableCell component="th" scope="row" className={cls.header}>
                {pageNumber * pageSize + index + 1}
            </TableCell>
            <TableCell style={{ width: 160 }} className={cls.cells}>
                {license.name_entity_ru}
            </TableCell>
            <TableCell style={{ width: 160 }} className={cls.cells}>
                {license.tax_name_ru}
            </TableCell>
            <TableCell style={{ width: 160 }} className={cls.buttonWrapper}>
                <Link to={`${license.id}`} className={cls.button}>
                    Посмотреть
                </Link>
                
            </TableCell>
            </TableRow>
        ))}
        </TableBody>
        <TableFooter>
        <TableRow>
            <TablePagination
                rowsPerPageOptions={[5, 10, 20, 25]}
                count={licenseList.length}
                rowsPerPage={pageSize}
                page={pageNumber}
                labelRowsPerPage={"По:"}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handlePageSizeChange}
                color="primary"
                className={cls.pagination}
            />
        </TableRow>
        </TableFooter>
    </Table>
    </TableContainer>
);
};

export default LicenseList;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CalculatorServices.scss";

const CalculatorServices = () => {
  const [carTypes, setCarTypes] = useState([]);
  const [selectedCar, setSelectedCar] = useState("");
  const [volumeOptions, setVolumeOptions] = useState([]);
  const [selectedVolume, setSelectedVolume] = useState("");
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [engineOptions, setEngineOptions] = useState([]);
  const [selectedEngine, setSelectedEngine] = useState("");
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [insuranceCost, setInsuranceCost] = useState(null);

  useEffect(() => {
    fetchCarTypes();
  }, []);

  const fetchCarTypes = () => {
    axios
      .get(`https://backend.tsvs.kg:5543/api/service/calculator`)
      .then((response) => {
        const uniqueCarTypes = [...new Set(response.data.map(option => option.car.car))];
        setCarTypes(uniqueCarTypes);
        console.log("Car types:", uniqueCarTypes);
        if (uniqueCarTypes.length > 0) {
          setSelectedCar(uniqueCarTypes[0]);
        }
      })
      .catch((error) => {
       
      });
  };

  useEffect(() => {
    if (selectedCar) {
      fetchOptions(selectedCar);
    }
  }, [selectedCar]);

  const fetchOptions = (carType) => {
    axios
      .get(`https://backend.tsvs.kg:5543/api/service/calculator?type=${carType}`)
      .then((response) => {
        const carData = response.data.filter(
          (option) => option.car.car === carType
        );
        const volumeOptions = carData.map((option) => option.volume.volume);
        const yearOptions = carData.map((option) => option.year.year);
        const engineOptions = carData.map((option) => option.engine.engine);
        const statusOptions = carData.map((option) => option.status.status);

        setVolumeOptions([...new Set(volumeOptions)]);
        setYearOptions([...new Set(yearOptions)]);
        setEngineOptions([...new Set(engineOptions)]);
        setStatusOptions([...new Set(statusOptions)]);
        setSelectedVolume(volumeOptions[0]);
      })
      .catch((error) => {
     
      });
  };

  const handleInputChange = (event, optionType) => {
    const value = event.target.value;
    switch (optionType) {
      case "car":
        setSelectedCar(value);
        break;
      case "volume":
        setSelectedVolume(value);
        break;
      case "year":
        setSelectedYear(value);
        break;
      case "engine":
        setSelectedEngine(value);
        break;
      case "status":
        setSelectedStatus(value);
        break;
      default:
        break;
    }
  };

  const calculateInsurance = async (event) => {
    event.preventDefault();
    
    try {
      const response = await axios.get(
        "https://backend.tsvs.kg:5543/api/service/calculator",
        {
          params: {
            car: selectedCar.trim(),
            volume: selectedVolume.trim(),
            year: selectedYear.trim(),
            engine: selectedEngine.trim(),
            status: selectedStatus.trim(),
          },
        }
      );
  
      console.log("Response from API:", response);
  
      const matchingOption = response.data.find((option) => (
        option.car.car.toLowerCase().includes(selectedCar.trim().toLowerCase()) &&
        option.volume.volume.toLowerCase().includes(selectedVolume.trim().toLowerCase()) &&
        option.year.year.toLowerCase().includes(selectedYear.trim().toLowerCase()) &&
        option.engine.engine.toLowerCase().includes(selectedEngine.trim().toLowerCase()) &&
        option.status.status.toLowerCase().includes(selectedStatus.trim().toLowerCase())
      ));
  
      console.log("Matching Option:", matchingOption);
  
      if (matchingOption) {
        const insuranceCost = parseInt(matchingOption.sum.replace(/\s/g, ""), 10);
        setInsuranceCost(insuranceCost);
        console.log("Insurance cost:", insuranceCost);
      } else {
        setInsuranceCost(null); 
        console.log("No matching option found");
      }
    } catch (error) {
      console.error("Error calculating insurance:", error);
    }
  };
  
  
  return (
    <div className="MainCalculatorContainer">
      <h2>Калькулятор оформления ТС</h2>
      <div className="VehicleRegistration">
        {/* Рендер селектов */}
        <div>
          <label>Тип авто:</label>
          <select
            onChange={(e) => handleInputChange(e, "car")}
            value={selectedCar}
          >
            {carTypes.map((carType) => (
              <option key={carType} value={carType}>
                {carType}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Объем:</label>
          <select
            onChange={(e) => handleInputChange(e, "volume")}
            value={selectedVolume}
          >
            {volumeOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Год:</label>
          <select
            onChange={(e) => handleInputChange(e, "year")}
            value={selectedYear}
          >
            {yearOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Тип двигателя:</label>
          <select
            onChange={(e) => handleInputChange(e, "engine")}
            value={selectedEngine}
          >
            {engineOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Статус оформления:</label>
          <select
            onChange={(e) => handleInputChange(e, "status")}
            value={selectedStatus}
          >
            {statusOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <button type="submit" onClick={calculateInsurance}>
        Рассчитать
      </button>
      <div style={{ marginTop: "20px" }}>
        <h3>Стоимость оформления:</h3>
        <p>{insuranceCost !== null ? `${insuranceCost} сом` : ""}</p>
      </div>
    </div>
  );
};

export default CalculatorServices;

import React, { useEffect, useState } from "react";
import axios from "axios";
import "./camera.scss";

const Camera = () => {
  const [streams, setStreams] = useState([]);
  const [selectedStreamUrl, setSelectedStreamUrl] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://backend.tsvs.kg:5543/api/online/cameras/live-streams");
        console.log(response);
        setStreams(response.data);
        if (response.data.length > 0) {
          setSelectedStreamUrl(response.data[0].url);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching streams", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const extractVideoId = (url) => {
    if (url.includes('/live/')) {
      return url.split('/live/').pop();
    }
    
    const urlObj = new URL(url);
    const videoId = urlObj.pathname.split('/').pop();
    
    return videoId;
  };

  const handleCardClick = (videoUrl) => {
    setSelectedStreamUrl(videoUrl);
  };

  console.log(selectedStreamUrl);

  if (loading) {
    return <div>LOADING</div>;
  }

  const getEmbedUrl = (url) => {
    if (url.includes("/live/")) {
      const videoId = extractVideoId(url);
      return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
    } else if (url.includes("youtube.com/channel/")) {
      const channelId = url.split("/channel/").pop();
      return `https://www.youtube.com/embed/live_stream?channel=${channelId}&autoplay=1`;
    } else {
      const videoId = extractVideoId(url);
      return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
    }
  };

  return (
    <div className="camera">
      <div className="camera__container">
        <div className="camera__left">
          {selectedStreamUrl && (
            <iframe
              title="YouTube Stream"
              width="100%"
              height="100%"
              src={getEmbedUrl(selectedStreamUrl)}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="camera__video"
            ></iframe>
          )}
        </div>
        <div className="camera__right">
          {streams.map((item) => (
            <div
              className="camera__block"
              key={item.id}
              onClick={() => handleCardClick(item.url)}
            >
              <img
                src={`https://img.youtube.com/vi/${extractVideoId(item.url)}/hqdefault.jpg`}
                alt={item.title}
                className="camera__block-img"
              />
              <p className="camera__block-title">{item.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Camera;

import { useEffect, useState } from "react";
import "../../pages/Home/home.scss";
import { useTranslation } from "react-i18next";
import HomeSearch from "../HomeSearch/HomeSearch";
import axios from "axios";

const HomeVideo = () => {
  const { t } = useTranslation();
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const response = await axios.get("https://backend.tsvs.kg:5543/api/index/video");
        if (response.data && response.data.length > 0) {
          setVideoUrl(response.data[0].file); 
        }        
      } catch (error) {
        
      }
    };

    fetchVideo();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobileScreen(screenWidth <= 770);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className={`home__container ${isMobileScreen ? "static-bg" : ""}`}>
        {isMobileScreen ? null : (
          <div className="home__video-bg">
             <div className="video__overlay"></div> 
            {videoUrl && (
              <video autoPlay loop muted loading="lazy" onContextMenu={handleContextMenu}>
                <source src={`https://backend.tsvs.kg:5543${videoUrl}`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              
            )}
          </div>
        )}
        <div className="home__content">
          <h6 className="home__content-title">{t("title")}</h6>
          <p className="home__content-subtitle">{t("desc")}</p>
        </div>

        <HomeSearch />
      </div>
    </>
  );
};

export default HomeVideo;

import { useState } from 'react';
import LicenseModal from './LicenseModal/LicenseModal.jsx';
import LicenseDocuments from './LicenseDocuments/LicenseDocuments.jsx';
import './license.scss';
import LicenseFaq from './LicenseFaq/LicenseFaq.jsx';
import LicenseContacts from './LicenseContacts/LicenseContacts.jsx';
import { useTranslation } from 'react-i18next';
import LicenseList from './LicenseList/LicenseList.jsx';

const License = () => {
  const [activeModal, setActiveModal] = useState(null);
  const {t} = useTranslation()
  const openModal = (index) => setActiveModal(index);
  const closeModal = () => setActiveModal(null);

  return (
    <section className='license'>
     <div className="license__button__container">
     <div className='license__button-npa' onClick={() => openModal(1)}>
      <h2 className='card__title'>{t("npa")}</h2>
      <button className='more'>{t("more")}</button>
     </div>
      <LicenseModal 
        isOpen={activeModal === 1} 
        onClose={closeModal} 
        apiUrl='https://backend.tsvs.kg:5543/api/lc/npa' 
      />

      <div className='license__button-doclist' onClick={() => openModal(2)}>
      <h2 className='card__title'>{t("doclist")}</h2>
      <button className='more'>{t("more")}</button>
      </div>
      <LicenseModal 
        isOpen={activeModal === 2} 
        onClose={closeModal} 
        apiUrl='https://backend.tsvs.kg:5543/api/lc/' 
      />

      <div className='license__button-requirements' onClick={() => openModal(3)}>
      <h2 className='card__title'>{t("licensereq")}</h2>
      <button className='more'>{t("more")}</button>
      </div>
      <LicenseModal 
        isOpen={activeModal === 3} 
        onClose={closeModal} 
        apiUrl='https://backend.tsvs.kg:5543/api/lc/doc/req' 
      />
     </div>
     <LicenseDocuments/>
     <LicenseFaq faqApiUrl="https://backend.tsvs.kg:5543/api/lc/faq-lc" />
     <LicenseList />
     <LicenseContacts/>
    </section>
  );
};

export default License;

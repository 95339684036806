import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import "./newsDetails.scss";
import he from "he";
import YouTube from "react-youtube";
import { HiOutlineEye } from "react-icons/hi2";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import useRelatedNews from "../../../hooks/NewsHooks/useRelatedNews";
import RelatedNews from "../RelatedNews/RelatedNews";
import ShareButtons from "../../../components/ShareButtons/ShareButtons";

const NewsDetails = () => {
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const relatedNews = useRelatedNews();
  const selectedLanguage = localStorage.getItem("selectedLanguage") || "kg";
  const hasIncrementedView = useRef(false);

  useEffect(() => {
    const fetchNewsItem = async () => {
      try {
        const response = await axios.get(`https://backend.tsvs.kg:5543/api/news/${id}`);
        setNewsItem(response.data);

        const parsedDate = new Date(response.data.published_date);
        const formattedDate = format(parsedDate, "d MMMM yyyy года", { locale: ru });
        setFormattedDate(formattedDate);
      } catch (error) {
        console.error("Error fetching news item", error);
      }
    };

    fetchNewsItem();
  }, [id]);

  if (!newsItem) {
    return <div>Загрузка...</div>;
  }

  const title = selectedLanguage === "ru" ? newsItem.title_ru : newsItem.title_ky;
  const text = selectedLanguage === "ru" ? newsItem.text_ru : newsItem.text_ky;
  const videoUrl = selectedLanguage === "ru" ? newsItem.url_youtube_ru : newsItem.url_youtube_kg;
  const videoId = videoUrl ? new URL(videoUrl).searchParams.get("v") : null;
  const shareUrl = window.location.href;

  return (
    <>
      <div className="news__info">
        <div className="news__details">
          <div className="news__details-info">
            <div className="news__details-image">
              <Swiper
                spaceBetween={30}
                navigation={{
                  clickable: true,
                }}
                modules={[Navigation]}
                className="news__swiper"
              >
                {newsItem.images.map((item, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={`https://backend.tsvs.kg:5543${item.image}`}
                      alt={`Slide ${index}`}
                      className="news__swiper-img"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="news__details-top">
              <div className="news__details-title">{he.decode(title)}</div>
              <div className="news__details-date">
                {formattedDate && <span>{formattedDate}</span>}
              </div>
              <div className="news__details-views">
                <HiOutlineEye />
                {newsItem.viewed}
              </div>
            </div>
            <div className="news__details-desc">
              <div className="news__details-desc-desc">
                {he
                  .decode(text)
                  .split("\n")
                  .map((paragraph, index) => (
                    <div
                      key={index}
                      className="news__details-paragraph"
                      dangerouslySetInnerHTML={{ __html: paragraph }}
                    />
                  ))}
              </div>
            </div>
           
            {videoId && (
              <YouTube
                videoId={videoId}
                className="news__details-video"
                opts={{ width: "95%", height: "200px" }}
              />
            )}
             <ShareButtons shareUrl={shareUrl} title={title} />
          </div>
        </div>

        <RelatedNews
          relatedNews={relatedNews}
          newsItem={newsItem}
          selectedLanguage={selectedLanguage}
        />
      </div>
    </>
  );
};

export default NewsDetails;

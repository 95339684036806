// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-scroll-top {
  position: fixed;
  bottom: 20px;
  right: -60px;
  transition: right 0.6s ease;
  z-index: 200;
  background-color: #18569e;
  color: white;
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border: 3px solid white;
}

.visible {
  right: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/ButtonScrollTop/buttonScrollTop.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;EACA,YAAA;EACA,2BAAA;EACA,YAAA;EAEA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,uBAAA;AAAJ;;AAGE;EACE,WAAA;AAAJ","sourcesContent":[".button-scroll-top {\n    position: fixed;\n    bottom: 20px;\n    right: -60px; \n    transition: right 0.6s ease; \n    z-index: 200; \n  \n    background-color: #18569e;\n    color: white;\n    border: none;\n    border-radius: 50%;\n    width: 45px;\n    height: 45px;\n    font-size: 18px; \n    line-height: 40px;\n    text-align: center;\n    cursor: pointer;\n    border: 3px solid white;\n  }\n  \n  .visible {\n    right: 30px; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

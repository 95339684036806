import { useState, useEffect } from 'react';
import cls from './LicenseDetails.module.scss';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { formatISODate } from '../../../utils/helpers/date-helpers';
import { PDFDownloadLink } from '@react-pdf/renderer';
import LicensePDF from '../LicensePDF/LicensePDF';
import LoaderDocument from '../../../components/LoaderDocument/LoaderDocument';
import QRCode from 'qrcode';

const LicenseDetails = () => {
    const [licenseData, setLicenseData] = useState({});
    const [loading, setLoading] = useState(true);
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [qrCodeUrlMinUst, setQrCodeUrlMinUst] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { id } = useParams();

    useEffect(() => {
        const fetchLicenseData = async () => {
            try {
                
                const responseLicense = await axios.get(`https://dev.tsvs.kg:5545/api/lc/list-licenses`);
                const responseIssuing = await axios.get(
                    `https://dev.tsvs.kg:5545/api/lc/issuing`
                );
                const responseStatus = await axios.get('https://dev.tsvs.kg:5545/api/lc/status')

                console.log(responseLicense.data[id-1].data_license)

                
                const data = {
                    ...responseLicense.data[id-1],
                    issuingState: responseIssuing.data[0].title_ru,
                    data_license: responseLicense.data[id-1].data_license,
                    status_code: responseStatus.data.filter(item => item.id === responseLicense.data[id-1].code_status_id)[0].title_ru
                };

                setLicenseData(data);

                const qrCodeText = `https://tsvs.gov.kg/license/${data.id}`;
                const minUstText = `https://online.minjust.gov.kg/user/search?operator=AND&page=0&size=10&tin=${responseLicense.data.tax_name}`;
                const url = await QRCode.toDataURL(qrCodeText);
                const minUstUrl = await QRCode.toDataURL(minUstText);

                setQrCodeUrl(url);
                setQrCodeUrlMinUst(minUstUrl);
            } catch (error) {
                setErrorMessage(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchLicenseData();
    }, [id]);

    if (loading) {
        return (
            <div className={cls.loaderWrapper}>
                <LoaderDocument />
            </div>
        );
    }

    return (
        <section className={cls.section}>
            {errorMessage ? (
                <p className={cls.errorMessage}>{errorMessage}</p>
            ) : (
                <>
                    <h2>{licenseData.name_entity_ru}</h2>
                    <PDFDownloadLink
                        document={
                            <LicensePDF
                                licenseData={licenseData}
                                qrCodeUrl={qrCodeUrl}
                                qrCodeUrlMinUst={qrCodeUrlMinUst}
                            />
                        }
                        fileName="license.pdf"
                        style={{
                            textDecoration: 'none',
                            color: '#fff',
                            backgroundColor: '#007BFF',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            marginTop: '20px',
                            display: 'inline-block',
                        }}
                    >
                        {({ loading: pdfLoading }) =>
                            pdfLoading ? (
                                <div className={cls.loaderWrapper}>
                                    <LoaderDocument />
                                </div>
                            ) : (
                                'Скачать PDF'
                            )
                        }
                    </PDFDownloadLink>
                    <div className={cls.details_wrapper}>
                        <div className={cls.details_item}>
                            <h4>Наименование лицензирующего органа:</h4>
                            <p>{licenseData.issuingState}</p>
                        </div>
                        <div className={cls.details_item}>
                            <h4>Наименование юридического лица:</h4>
                            <p>{licenseData.name_entity_ru}</p>
                        </div>
                        <div className={cls.details_item}>
                            <h4>Организационно-правовая форма:</h4>
                            <p>{licenseData.status_code}</p>
                        </div>
                        
                        <div className={cls.details_item}>
                            <h4>Лицензируемый вид деятельности:</h4>
                            <p>
                                Образовательная деятельность по подготовке и переподготовке водителей
                                транспортных средств
                            </p>
                        </div>
                        <div className={cls.details_item}>
                            <h4>ИНН:</h4>
                            <p>{licenseData.tax_name_ru}</p>
                        </div>
                        <div className={cls.details_item}>
                            <h4>Срок действия лицензии:</h4>
                            <p>{licenseData.re_registration_ru}</p>
                        </div>
                        <div className={cls.details_item}>
                            <h4>Регистрационный номер лицензии:</h4>
                            <p>{licenseData.number_register_ru}</p>
                        </div>
                        <div className={cls.details_item}>
                            <h4>Дата выдачи:</h4>
                            <p>{formatISODate(licenseData.data_license)}</p>
                        </div>
                    </div>
                </>
            )}
        </section>
    );
};

export default LicenseDetails;

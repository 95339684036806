import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './licenseModal.scss';

const LicenseModal = ({ isOpen, onClose, apiUrl, backgroundImage }) => {
  const [data, setData] = useState([]);
  const selectedLanguage = localStorage.getItem('selectedLanguage') || 'ru';

  useEffect(() => {
    if (isOpen) {
      axios.get(apiUrl)
        .then(response => setData(response.data))
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [isOpen, apiUrl]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box className='modal-content' style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }}>
        <IconButton
          className='modal-close'
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        {data.length > 0 ? (
          data.map((item) => (
            <div key={item.id}>
              {/* <Typography variant="p" id="modal-title">
                {selectedLanguage === 'ru' ? item.title_ru : item.title_kg}
              </Typography> */}
              <div
                id="modal-description"
                dangerouslySetInnerHTML={{ __html: selectedLanguage === 'ru' ? item.text_ru : item.text_ky }}
              />
            </div>
          ))
        ) : (
          <Typography>Загрузка...</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default LicenseModal;

import React from "react";
import { BiSolidCar } from "react-icons/bi";
import './contacts.scss';
import useContacts from "../../../../hooks/useContacts"

const Contacts = () => {
  const { contacts, loading, error } = useContacts();

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>Error: {error}</span>;
  }

  if (contacts.length === 0) {
    return <span>Contact data is not available.</span>;
  }

  return (
    <div className="row">
      {contacts.map((contact) => (
        <div key={contact.address}>
          <div className="MainCardItem">
            <div className="card">
              <div className="info">
                <BiSolidCar className="icon" />
                <div className="title">
                  <span className="MainTitle">{contact.title}</span>
                  <span>{contact.phone}</span>
                </div>
                <div className="address">
                  <span dangerouslySetInnerHTML={{ __html: contact.address }} />
                </div>
                <span className="date" dangerouslySetInnerHTML={{ __html: contact.time_job }} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Contacts;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.share-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 90%;
  padding: 0 10px;
  margin-top: 60px;
}
.share-buttons h3 {
  margin-bottom: 10px;
}
.share-buttons > * {
  margin: 5px;
}

@media (max-width: 900px) {
  .share-buttons {
    padding: 0 20px;
  }
}
@media (max-width: 450px) {
  .share-buttons {
    flex-wrap: wrap;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ShareButtons/shareButtons.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,UAAA;EACA,eAAA;EACA,gBAAA;AACF;AAAE;EACE,mBAAA;AAEJ;AACE;EACE,WAAA;AACJ;;AAGA;EACE;IACE,eAAA;EAAF;AACF;AAGA;EACE;IACE,eAAA;EADF;AACF","sourcesContent":[".share-buttons {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  width: 90%;\n  padding: 0 10px;\n  margin-top: 60px;\n  h3 {\n    margin-bottom: 10px;\n  }\n\n  > * {\n    margin: 5px;\n  }\n}\n\n@media (max-width: 900px) {\n  .share-buttons {\n    padding: 0 20px; \n  }\n}\n\n@media (max-width: 450px) {\n  .share-buttons {\n    flex-wrap: wrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import he from "he";
import { useTranslation } from "react-i18next";
import "../NewsDetails/newsDetails.scss";

const RelatedNews = ({ relatedNews, newsItem, selectedLanguage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="news__right">
      <div className="news__right-container">
        {relatedNews
          .filter((relatedNewsItem) => relatedNewsItem.id !== newsItem.id)
          .slice(0, 4)
          .map((relatedNewsItem) => {
            const trimmedTitle =
              selectedLanguage === "ru"
                ? relatedNewsItem.title_ru.split(" ").slice(0, 20).join(" ")
                : relatedNewsItem.title_ky.split(" ").slice(0, 20).join(" ");
            const displayTitle =
              selectedLanguage === "ru"
                ? relatedNewsItem.title_ru.split(" ").length > 20
                  ? `${trimmedTitle}...`
                  : trimmedTitle
                : relatedNewsItem.title_ky.split(" ").length > 20
                ? `${trimmedTitle}...`
                : trimmedTitle;
            const firstImage =
              relatedNewsItem.images.length > 0
                ? relatedNewsItem.images[0].image
                : null;

            return (
              <div
                key={relatedNewsItem.id}
                className="news__right-extra"
                onClick={() => navigate(`/news/${relatedNewsItem.id}`)}
              >
                {firstImage && (
                  <img
                    className="news__right-extra-img"
                    src={`https://backend.tsvs.kg:5543${firstImage}`}
                    alt="Related News"
                  />
                )}
                <div className="news__right-extra-info">
                  {relatedNewsItem.published_date && (
                    <span className="news__right-extra-date">
                      {format(
                        new Date(relatedNewsItem.published_date),
                        "d MMMM yyyy года",
                        { locale: ru }
                      )}
                    </span>
                  )}
                  <div className="news__right-extra-title">
                    {he.decode(displayTitle)}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default RelatedNews;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search {
  width: 100%;
  margin-top: 50px;
}
.search__title {
  margin: 45px 135px;
}
.search__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  gap: 20px;
}
.search__card {
  width: 1250px;
  border: 2px solid #e5e5e5;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 20px;
}
.search__card-link {
  color: #4285f4;
  font-size: 17px;
  text-align: end;
  text-decoration: none;
}
.search__card-date {
  color: gray;
}
.search__notfound {
  margin: 0 auto;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.search__notfound-title {
  font-size: 40px;
  text-align: center;
}

.highlight {
  background-color: #4285f4;
}

@media (max-width: 1300px) {
  .search__card {
    width: auto;
    margin: 20px;
  }
  .search__title {
    margin: 40px 0;
  }
  .search__container {
    gap: 0;
  }
}
@media (max-width: 450px) {
  .search__notfound-title {
    font-size: 27px;
    text-align: left;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Search/search.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;AACJ;AAAI;EACI,kBAAA;AAER;AAAI;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,SAAA;AAEN;AACI;EACE,aAAA;EACA,yBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;AACN;AAAM;EACE,cAAA;EACA,eAAA;EACA,eAAA;EACA,qBAAA;AAER;AACM;EACE,WAAA;AACR;AAEI;EACI,cAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AAAR;AACQ;EACI,eAAA;EACA,kBAAA;AACZ;;AAIE;EACE,yBAAA;AADJ;;AAIE;EACE;IACE,WAAA;IACA,YAAA;EADJ;EAGE;IACE,cAAA;EADJ;EAGE;IACE,MAAA;EADJ;AACF;AAIE;EACE;IACE,eAAA;IACA,gBAAA;EAFJ;AACF","sourcesContent":[".search {\n    width: 100%;\n    margin-top: 50px;\n    &__title{\n        margin: 45px 135px;\n    }\n    &__container {\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n      align-items: center; \n      margin: 30px 0;\n      gap: 20px;\n    }\n  \n    &__card {\n      width: 1250px;\n      border: 2px solid #e5e5e5;\n      padding: 25px;\n      display: flex;\n      flex-direction: column;\n      gap: 15px;\n      border-radius: 20px;\n      &-link {\n        color: #4285f4;\n        font-size: 17px;\n        text-align: end;\n        text-decoration: none;\n      }\n  \n      &-date {\n        color: gray;\n      }\n    }\n    &__notfound{\n        margin: 0 auto;\n        height: 500px;\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        &-title{\n            font-size: 40px;\n            text-align: center;\n        }\n    }\n  }\n\n  .highlight{\n    background-color: #4285f4;\n  }\n\n  @media  (max-width: 1300px) {\n    .search__card{\n      width: auto;\n      margin: 20px;\n    }\n    .search__title{\n      margin: 40px 0;\n    }\n    .search__container{\n      gap: 0;\n    }\n  }\n\n  @media  (max-width: 450px) {\n    .search__notfound-title {\n      font-size: 27px;\n      text-align: left;\n    }\n  }\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

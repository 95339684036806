import React, {useEffect, useState} from 'react'
import './npa.scss'
import axios from 'axios';
import pdf from '../../assets/icon/pdf.png'

const Npa = () => {
    const [buttons, setButtons] = useState([]);

    useEffect(() => {
      const getButtons = async () => {
        try {
          const response = await axios.get("https://backend.tsvs.kg:5543/api/npa/pdf");
          setButtons(response.data);
        } catch (err) {
          console.error(err);
        }
      };
      getButtons();
    }, []);
  
    return (
       <section className='npa'>
       <h2 className='npa__title'>Нормативно-правовые акты</h2>
       <div className='npa__container'>
        {buttons.map((button) => (
          <a 
            key={button.id} 
            href={`https://backend.tsvs.kg:5543${button.file}`} 
            className='npa__docs' 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <img src={pdf} alt="DOC" className='npa__icon' />
            {button.title}
          </a>
        ))}
        </div>
       </section>
    );
  };
export default Npa
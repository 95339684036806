// Регистрация прав собственности на трактора и оборудование

import React from "react";
import useTractorOwnRegisterData from "../../../hooks/InformationHooks/useTractorOwnRegisterData";

const TractorOwnRegister = () => {

  const { data, loading, error } = useTractorOwnRegisterData();

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>Error fetching data: {error}</div>;
  }


  return (
    <div className="information__table">
      <h3 className="information__table-title">
        Регистрация (перерегистрация) прав собственности на трактора, тракторные
        прицепы, самоходные технологические установки с двигателями внутреннего
        сгорания (кроме технических устройств, применяемых на опасных
        производственных обьектах) с выдачей правоудостоверяющих документов,
        регистрационных номерных знаков
      </h3>
      <table>
        <thead>
          <tr>
            <th>№</th>
            <th>Наименование услуги</th>
            <th>Стоимость (сом)</th>
          </tr>
        </thead>
        <tbody>
         {
          data.map((item) => (
            <tr key={item.number}>
            <td className="number">{item.number}</td>
            <td className="middle">
              {item.title}
            </td>
            <td className="price">{item.price}</td>
          </tr>
          ))
         }
        </tbody>
      </table>
    </div>
  );
};

export default TractorOwnRegister;

import React from "react";
import "./footer.scss";
import { useTranslation } from "react-i18next";
import { LuInstagram } from "react-icons/lu";
import { FaFacebookF } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import img from "../../assets/img/v3.png";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer__container">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f1/Emblem_of_Kyrgyzstan.svg/200px-Emblem_of_Kyrgyzstan.svg.png"
          alt=""
          className="footer__img"
        />
        <div className="footer__links">
          <h3 className="footer__contacts-title">{t("footer.contact")}</h3>
          <p className="footer__phone">
            Канцелярия:
            <br /> +996(312)-63-17-27
          </p>
          <p className="footer__phone">
            Отдел лицензирования:
            <br /> +996(312)-44-71-71
          </p>
          <p className="footer__phone">
            Транспортный состав:
            <br /> +996(312)-63-17-37
            <br /> +996(312)-63-17-14
          </p>
          <p className="footer__phone">
            Водительский состав:
            <br /> +996(312)-63-39-31 <br /> +996(312)-63-28-33
          </p>
          <p className="footer__email">office@tsvs.gov.kg</p>
          <address className="footer__address">{t("footer.address")}</address>
          <div className="socials">
            <a
              href="https://www.instagram.com/tsvs.gov.kg?igsh=cGU1c2V1eWthd2Zp&utm_source=qr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LuInstagram />
            </a>

            <a
              href="https://www.facebook.com/Unaa.mam.mekemesi?mibextid=LQQJ4d"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF />
            </a>

            <a
              href="https://www.whatsapp.com/channel/0029VaLnG5D89indYhaHqn0U"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp />
            </a>
            <a
              href="https://t.me/tsvsgovkg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTelegramPlane />
            </a>
            <a
              href="https://www.youtube.com/@tsvsgovkg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube />
            </a>
          </div>
        </div>

        <div className="footer__links">
          <h3 className="footer__links-title">{t("footer.navigation")}</h3>

          {/* <a href="/" className="footer__link">
              {t("home")}
            </a> */}
          <a href="/about" className="footer__link">
            {t("about")}
          </a>
          <a href="/news" className="footer__link">
            {t("news")}
          </a>
          <a href="/pricing" className="footer__link">
            {t("info.price")}
          </a>
          <a href="/license" className="footer__link">
            {t("info.license")}
          </a>
          <a href="/vs" className="footer__link">
          {t("vs")}
          </a>
          <a href="/ts" className="footer__link">
          {t("ts")}
          </a>
          <a href="/license" className="footer__link">
            {t("service.inspection")}
          </a>
          <a href="/jobs" className="footer__link">
            {t("jobs")}
          </a>
          <a href="/camera" className="footer__link">
            {t("camera")}
          </a>
          <a href="/npa" className="footer__link">
            {t("footer.npa")}
          </a>
          {/* <a href="/faq" className="footer__link">
            {t("faq.title")}
          </a> */}
        </div>

        <div className="footer__links">
          <h3 className="footer__links-title">{t("footer.government")}</h3>

          <a href="https://president.kg/" className="footer__link">
            {t("footer.president")}
          </a>
          <a href="https://kenesh.kg/ru" className="footer__link">
            {t("footer.kenesh")}
          </a>
          <a href="https://port al.tunduk.kg/" className="footer__link">
            {t("footer.portal")}
          </a>
          <a href="/" className="footer__link">
            {t("footer.gosregister")}
          </a>
          <a href="https://tunduk.gov.kg/ru" className="footer__link">
            {t("footer.tyndyk")}
          </a>
        </div>
      </div>

      <p className="footer__text">{t("footer.bottom")}</p>
    </footer>
  );
};

export default Footer;

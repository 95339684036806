// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: absolute;
  width: 80%;
  max-width: 1000px;
  max-height: 90vh;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 50px;
  border-radius: 8px;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-close {
  position: absolute !important;
  top: 8px;
  right: 8px;
}

@media (max-width: 450px) {
  .modal-content {
    padding: 45px 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/License/LicenseModal/licenseModal.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,aAAA;EACA,oCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;EACA,iBAAA;EACA,gBAAA;EACA,sBAAA;EACA,sBAAA;EACA,wCAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AACF;;AAEA;EACE,6BAAA;EACA,QAAA;EACA,UAAA;AACF;;AAGA;EACE;IACE,kBAAA;EAAF;AACF","sourcesContent":[".modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.modal-content {\n  position: absolute;\n  width: 80%;\n  max-width: 1000px;\n  max-height: 90vh;\n  background-color: #fff;\n  border: 2px solid #000;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n  padding: 50px;\n  border-radius: 8px;\n  overflow-y: auto;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.modal-close {\n  position: absolute!important;\n  top: 8px;\n  right: 8px;\n}\n\n\n@media (max-width:450px) {\n  .modal-content{\n    padding: 45px 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

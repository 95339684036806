import React from "react";
import DOMPurify from "dompurify";
import he from "he";
import "./management.scss";
import useManagementData from "../../../../hooks/useManagementData.js";

const Management = () => {
  const { managementData, setManagementData } = useManagementData();

  const toggleDescription = (index) => {
    const newData = [...managementData];
    newData[index].showFullDescription = !newData[index].showFullDescription;
    setManagementData(newData);
  };

  return (
    <div className="management">
      <div className="management__container">
        {managementData.map((item, index) => (
          <div key={index} className="management__card">
            <img
              className="management__img"
              src={`https://backend.tsvs.kg:5543${item.avatar}`}
              alt={item.name}
            />
            <div className="management__info">
              <div className="management__role">
                <span className="management__title">{item.name}</span>
                <span className="management__status">{item.title}</span>
              </div>
              <p
                className="management__desc"
                onClick={() => toggleDescription(index)}
                style={{ cursor: "pointer" }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    he.decode(
                      item.showFullDescription
                        ? item.description_ru + " Свернуть"
                        : item.description_ru.slice(0, 600) + "         ...Ещё"
                    )
                  ),
                }}
              ></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Management;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LicenseDetails_section__A8Ozk {
  margin-top: 150px;
  margin-bottom: 150px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LicenseDetails_details_wrapper__vSY0F {
  margin-top: 50px;
  width: 75%;
}

.LicenseDetails_details_item__NYFhT {
  margin-top: 30px;
}

.LicenseDetails_loaderWrapper__Wntqx {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/License/LicenseDetails/LicenseDetails.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,oBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,UAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;AACJ","sourcesContent":[".section {\n    margin-top: 150px;\n    margin-bottom: 150px;\n    height: auto;\n    display: flex;\n    flex-direction: column;  \n    align-items: center;\n}\n\n.details_wrapper {\n    margin-top: 50px;\n    width: 75%;\n}\n\n.details_item {\n    margin-top: 30px;\n}\n\n.loaderWrapper {\n    position: absolute;\n    top: 0;\n    left: 0;\n    min-width: 100%;\n    min-height: 100vh;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `LicenseDetails_section__A8Ozk`,
	"details_wrapper": `LicenseDetails_details_wrapper__vSY0F`,
	"details_item": `LicenseDetails_details_item__NYFhT`,
	"loaderWrapper": `LicenseDetails_loaderWrapper__Wntqx`
};
export default ___CSS_LOADER_EXPORT___;

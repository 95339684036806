import { useState, useEffect } from "react";
import axios from "axios";

const useManagementData = () => {
  const [managementData, setManagementData] = useState([]);

  useEffect(() => {
    const fetchManagementData = async () => {
      try {
        const response = await axios.get(
          "https://backend.tsvs.kg:5543/api/about/management"
        );
        setManagementData(
          response.data.map((item) => ({
            ...item,
            showFullDescription: false,
          }))
        );
      } catch (error) {
        console.error("Error fetching management data:", error);
      }
    };

    fetchManagementData();
  }, []);

  return { managementData, setManagementData };
};

export default useManagementData;

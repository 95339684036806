import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import "./news.scss";
import NewsCard from "./NewsCard/NewsCard";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import useNewsData from "../../hooks/NewsHooks/useNewsData";

const News = () => {
  const { newsData, totalPages, currentPage, setPage, getDisplayIndexes } = useNewsData();

  const { startIndex, endIndex } = useCallback(getDisplayIndexes, [currentPage, newsData])();

  const handlePageChange = useCallback((event, value) => {
    setPage(value);
  }, [setPage]);

  return (
    <section className="news">
      <div className="news__container">
        {newsData.slice(startIndex, endIndex).map((newsItem) => (
          <NewsCard key={newsItem.id} newsItem={newsItem} />
        ))}
      </div>
      <Stack style={{ margin: "10px" }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
   
          shape="rounded"
          component={Link}
        />
      </Stack>
    </section>
  );
};
 
export default News;
 
import React from "react";
import "./RequestInspection.scss";
import InspectionForm from "../../../components/InspectionForm/InspectionForm";
import { useTranslation } from "react-i18next";

const RequestInspection = () => {
  const { t } = useTranslation();

  return (
    <section className="inspection">
      <div className="inspection__top">
        <div className="inspection__top-content">
          <h2 className="inspection__top-title">
            {t("service.inspectionTitle")}
          </h2>
          <p className="inspection__top-text">{t("service.inspectionDesc")}</p>
        </div>
      </div>

      <div className="inspection__container">
        <InspectionForm />
        <div className="inspection__faq">
          <h2 className="inspection__faq-title">
            {t("service.inspectionFaq")}
          </h2>
          <div className="inspection__block">
            <h3 className="inspection__faq-subtitle">
              {t("service.department")}
            </h3>
            <p className="inspection__faq-text">
              {t("service.inspectionNearby")}
            </p>
          </div>
          <div className="inspection__block">
            <h3 className="inspection__faq-subtitle">
              {t("service.fullName")}
            </h3>
            <p className="inspection__faq-text">
              {t("service.inspectionFullName")}
            </p>
          </div>
          <div className="inspection__block">
            <h3 className="inspection__faq-subtitle">
              {t("service.phoneNumber")}
            </h3>
            <p className="inspection__faq-text">
              {t("service.inspectionNumber")}
            </p>
          </div>

          <div className="inspection__block">
            <h3 className="inspection__faq-subtitle">{t("service.email")}</h3>
            <p className="inspection__faq-text">
              {t("service.inspectionEmail")}
            </p>
          </div>

          <div className="inspection__block">
            <h3 className="inspection__faq-subtitle">
              {t("service.carNumber")}
            </h3>
            <p className="inspection__faq-text">
              {t("service.inspectionCarNumber")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RequestInspection;

import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import "./burgerMenu.scss";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import { FaRegUser } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import RegisterForm from "../RegisterForm/RegisterForm";
import { useDispatch, useSelector } from "react-redux";
import { logOutAccount } from "../../redux/reducers/userSlice";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BurgerMenu = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [isAccordionActive, setAccordionActive] = useState(false);
  const [isInfoAccordionActive, setInfoAccordionActive] = useState(false);

  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.username);
  const isLoggedIn = !!user;

  useEffect(() => {
    setMenuOpen(false);
    document.body.classList.remove("scroll-blocked");
  }, [location]);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      document.body.classList.add("scroll-blocked");
    } else {
      document.body.classList.remove("scroll-blocked");
    }
  };

  const toggleAccordion = () => {
    setAccordionActive(!isAccordionActive);
  };

  const toggleInfoAccordion = () => {
    setInfoAccordionActive(!isInfoAccordionActive);
  };

  const toggleRegisterForm = () => {
    setShowRegisterForm(!showRegisterForm);
    setMenuOpen(false);
    document.body.classList.remove("scroll-blocked");
  };

  const handleLogout = () => {
    setMenuOpen(false);
    document.body.classList.remove("scroll-blocked");
    dispatch(logOutAccount());
  };

  return (
    <div className={`burger-menu ${isMenuOpen ? "menu-open" : ""}`}>
      <div className="burger-icon" onClick={toggleMenu}>
        <FaBars />
      </div>

      <nav className={`menu ${isMenuOpen ? "show-menu" : ""}`}>
        <LanguageSelect />
        <span className="close-icon" onClick={toggleMenu}>
          <FaTimes />
        </span>

        <ul>
          <li>
            <Link to="/about">{t("about")}</Link>
          </li>
          <li>
            <Link to="/news">{t("news")}</Link>
          </li>

          {/* Аккордеон Информации */}
          <li
            className={`accordion__burger ${
              isInfoAccordionActive ? "active" : ""
            }`}
            onClick={toggleInfoAccordion}
          >
            <Accordion className="accordion__burger-menu">
              <AccordionSummary
                className="accordion__head"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="info-panel-content"
                id="info-panel-header"
              >
                <Typography>{t("info.main")}</Typography>
              </AccordionSummary>
              <AccordionDetails className="submenu__details">
                <div className="submenu">
                  <li className="submenu-item">
                    <Link to="/vs">{t("vs")}</Link>
                  </li>
                  <li className="submenu-item">
                    <Link to="/ts">{t("ts")}</Link>
                  </li>
                  <li className="submenu-item">
                    <Link to="/license">{t("info.license")}</Link>
                  </li>
                </div>
              </AccordionDetails>
            </Accordion>
          </li>

          {/* Аккордеон Сервисов */}
          <li
            className={`accordion__burger ${isAccordionActive ? "active" : ""}`}
            onClick={toggleAccordion}
          >
            <Accordion className="accordion__burger-menu">
              <AccordionSummary
                className="accordion__head"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="service-panel-content"
                id="service-panel-header"
              >
                <Typography>{t("service.main")}</Typography>
              </AccordionSummary>
              <AccordionDetails className="submenu__details">
                <div className="submenu">
                  <li className="submenu-item">
                    <Link to="/calculator">{t("service.calculator")}</Link>
                  </li>
                  <li className="submenu-item">
                    <Link to="/request-for-inspection">
                      {t("service.inspection")}
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link to="https://kattoo-prava.srs.kg/">
                      {t("studentRegister")}
                    </Link>
                  </li>
                </div>
              </AccordionDetails>
            </Accordion>
          </li>
          <li>
            <Link to="/camera">{t("camera")}</Link>
          </li>
        </ul>

        <div className="menu__bottom">
          {isLoggedIn ? (
            <button className="menu__bottom-btn" onClick={handleLogout}>
              {t("exit")} <FaRegUser />
            </button>
          ) : (
            <button className="menu__bottom-btn" onClick={toggleRegisterForm}>
              {t("login")} <FaRegUser />
            </button>
          )}
        </div>
      </nav>

      {showRegisterForm && (
        <RegisterForm onClose={() => setShowRegisterForm(false)} />
      )}
    </div>
  );
};

export default BurgerMenu;

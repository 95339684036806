// хук для получения данных в секции Information, в поле "Подтверждение сведений о транспортных средствах и водительских удостоверениях"

import { useEffect, useState } from "react";
import axios from "axios";
import { set } from "date-fns";

const useLicenseOwnConfirmData = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://backend.tsvs.kg:5543/api/information/conf"
        );
        setData(response.data);
      } catch (error) {
      
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return { data, loading, error };
};

export default useLicenseOwnConfirmData;

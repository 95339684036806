import React from "react";
import './history.scss'

const History = () => {
  return (
    <div className="history">
      <div className="history__container">
        <h1 className="history__title">История</h1>
      </div>
    </div>
  );
};

export default History;

const translations = {
  ru: {
    translation: {
      title:
        "Государственное агентство по регистрации транспортных средств и водительского состава при Кабинете Министров Кыргызской Республики",
      desc: "Мы осуществляем деятельность в сфере регистрации транспортных средств и водительского состава",
      search: "Поиск...",
      home: "Главная",
      about: "О нас",
      news: "Новости",
      info: {
        main: "Информация",
        price: "Прейскурант цен",
        license: "Лицензирование",
      },
      doclist: "Перечень документов",
      vs: "Водительский состав",
      ts: "Транспортный состав",
      licensereq: "Дополнительные лицензионные требования",
      history: "Об агенстве",
      management: "Руководство",
      contact: "Контакты",
      service: {
        main: "Сервисы",
        calculator: "Калькулятор",
        inspection: "Обращение на осмотр",
        inspectionTitle:
          "Оставьте заявку на выездной осмотр вашего транспортного средства",
        inspectionDesc:
          "Оставьте свои контактные данные в форме ниже, и наши сотрудники свяжутся с вами в самое ближайшее время",
        inspectionFaq:
          "Инструкция по заполнению формы электронной подачи заявки на выездной осмотр",
        inspectionNearby:
          "1. Необходимо выбрать наиболее близко расположенный к Вам отдел или сектор",
        inspectionFullName:
          "2. Необходимо заполнить в поле Фамилия Имя Отчество (Ф.И.О.)",
        inspectionNumber:
          "3. Необходимо указать номер мобильного телефона для связи сотрудника с Вами",
        inspectionEmail:
          "4. Необходимо указать адрес электронной почты для обратной связи",
        inspectionCarNumber:
          "5. Необходимо указать государственный номер автомобиля",
        phone: "Телефон",
        region: "Область",
        departamnt: "Отделение",
        fullName: "Ф.И.О.",
        phoneNumber: "Номер телефона",
        email: "Электронный адрес",
        carNumber: "Номер автомобиля",
        register: "Пройдите регистрацию",
        send: "Отправить",
      },
      information: {
        title:
          "Прейскурант тарифов (цен) на платные услуги, оказываемые Государственным агентством по регистрации транспортных средств и водительского состава при Кабинете Министров Кыргызской Республики",
      },
      jobs: "Вакансии",
      camera: "Онлайн камеры",
      notfound: "По вашему запросу ничего не найдено",
      homeNewsTitle: "Последние новости",
      ourServices: "Полезные ссылки",
      ourServicesDescription:
        "Наше Агентство предоставляет вам возможность получить выписку по TC " +
        "проверить штрафы, узнать владельца TC и многое другое",
      registerTitle: "Регистрация",
      login: "Войти",
      exit: "Выйти",
      also: "Другие новости",
      npa: "НПА",
      more: "Подробнее",
      faq: {
        title: "Часто задаваемые вопросы",
        transport:
          "Вопросы по регистрации/перерегистрации транспортных средств",
        driver: "Вопросы водительского состава",
      },
      appeal: {
        title: "Онлайн обращение",
        desc: "Пожалуйста, заполните форму чтобы связаться с нами. Мы ценим ваше мнение и постараемся ответить на ваше сообщение как можно скорее.",
      },
      footer: {
        navigation: "Навигация по сайту",
        npa: "НПА",
        government: "Государственные структуры",
        contact: "Контактные данные",
        president: "Сайт Президента КР",
        kenesh: "Жогорку Кенеш",
        portal: "Портал электронных услуг",
        gosregister: "Государственная регистрационная служба",
        tyndyk: "Система «ТҮНДҮК»",
        address: "ул. Чокана Валиханова, 2 а 720048, г.Бишкек",
        bottom:
          "Государственное агентство по регистрации транспортных средств и водительского состава при Кабинете Министров Кыргызской Республики",
      },
      studentRegister: "Онлайн регистрация курсантов",
      user: {
        name: "Имя",
        surname: "Фамилия",
        patronymic: "Отчество",
        appealText: "Текст обращения",
        email: "Адрес электронной почты",
        phone: "Номер телефона",
        password: "Пароль",
        register: "Зарегистрироваться",
        isRegistered: "Уже есть аккаунт?",
        continueWith: "Продолжить с",
      },
    },
  },
  kg: {
    translation: {
      title:
        "Кыргыз Республикасынын Министрлер Кабинетине караштуу Транспорт каражаттарын жана айдоочулук курамды каттоо боюнча мамлекеттик агенттик",
      desc: "Биз транспорт каражаттарын жана айдоочулук курамды каттоо чөйрөсүндө иш жүргүзөбүз",
      search: "Издөө...",
      home: "Башкы бет",
      about: "Биз жөнүндө",
      news: "Жаңылыктар",
      info: {
        main: "Маалымат",
        price: "Баалардын прейскуранты",
        license: "Лицензиялоо бөлүмү",
      },
      doclist: "Документтердин тизмеси",
      vs: "Айдоочулук курам",
      ts: "Транспорттук курам",
      licensereq: "Лицензиялоонун кошумча талаптары",
      history: "Агенттик жөнүндө",
      management: "Жетекчилик",
      contact: "Байланыш",
      service: {
        main: "Кызматтар",
        calculator: "Калькулятор",
        inspection: "Текшерүүгө кайрылуу",
        inspectionTitle:
          "Унааңызды онлайн режиминде текшерүүгө арыз калтырыңыз.",
        inspectionDesc:
          "Байланыш маалыматыңызды төмөнкү формада калтырыңыз жана биздин кызматкерлер жакын арада сиз менен байланышат.",
        inspectionFaq:
          "Көчмө кароого өтүнмөнү берүүнүн электрондук формасын толтуруу боюнча нускама",
        inspectionNearby:
          "1. Сизге жакын жайгашкан бөлүмдү же секторду тандап алыңыз.",
        inspectionFullName:
          "2. Фамилияңызды, атыңызды, атаңыздын атын (Ф.А.А.) талаачага жазыңыз.",
        inspectionNumber:
          "3. Адис Сиз менен байланышат, бул үчүн өзүңүздүн уюлдук телефон номериңизди жазыңыз.",
        inspectionEmail:
          "4. Кайтарым байланыш үчүн электрондук почтаңыздын дарегин жазыңыз.",
        inspectionCarNumber: "5. Автомобилдин мамлекеттик номерин жазыңыз.",
        phone: "Байланыш",
        region: "Облус",
        department: "Бөлүм",
        fullName: "Толук аты-жөнүңүз",
        phoneNumber: "Телефон номери",
        email: "Электрондук почта дареги",
        carNumber: "Унаанын номери",
        register: "Каттоодон өтүңүз",
        send: "Жөнөтүү",
      },
      information: {
        title:
          "Кыргыз Республикасынын Министрлер Кабинетине караштуу транспорт каражаттарын жана айдоочулук курамды каттоо мамлекеттик агенттик тарабынан көрсөтүлүүчү акы төлөнүүчү кызматтарга тарифтердин (баалардын) прейскуранты",
      },
      jobs: "Вакансиялар",
      camera: "Онлайн камералар",
      notfound: "Сиздин талап боюнча эч нерсе табылган жок",
      homeNewsTitle: "Акыркы жаңылыктар",
      ourServices: "Пайдалуу  шилтемелер",
      ourServicesDescription:
        "Биздин Агенттик сизге бажы кызматы боюнча көчүрмө алууга мүмкүнчүлүк берет, " +
        "айыптарды текшерүү, ТКнын ээсин билүү ж.б.",
      registerTitle: "Каттоо",
      login: "Кирүү",
      exit: "Чыгуу",
      also: "Башка жаңылыктар",
      npa: "ЧУА",
      more: "Кененирээк",
      faq: {
        title: "Көп берилүүчү суроолор",
        transport: "Транспорт каражаттарын каттоо/кайра каттоо боюнча суроолор",
        driver: "Айдоочулук курам боюнча суроолор",
      },
      appeal: {
        title: "Онлайн кайрылуу",
        desc: "Сураныч, биз менен байланышуу учун форманы толтуруңуз. Биз сиздин пикириңизди баалайбыз жана билдирүүңүзгө тезирээк жооп берүүгө аракет кылабыз.",
      },
      footer: {
        navigation: "Сайттын навигациясы",
        npa: "ЧУА",
        government: "Мамлекеттик структуралар",
        contact: "Байланыш маалыматтары",
        president: "Кыргыз Республикасынын Президентинин сайты",
        kenesh: "Жогорку Кеңеш",
        portal: "Электрондук кызмат көрсөтүүлөр мамлекеттик порталы",
        gosregister: "Мамлекеттик каттоо кызматы",
        tyndyk: "«Түндүк» системасы",
        address: "Бишкек шаары, Чокан Валиханов көчөсү, 2 а 720048",
        bottom:
          "Кыргыз Республикасынын Министрлер Кабинетине караштуу Транспорт каражаттарын жана айдоочулук курамды каттоо боюнча мамлекеттик агенттик",
      },
      studentRegister: "Курсанттарды онлайн каттоо",
      user: {
        name: "Аты",
        surname: "Фамилиясы",
        patronymic: "Атасынын аты",
        appealText: "Кайрылуунун тексти",
        email: "Электрондук почта дареги",
        phone: "Телефон номери",
        password: "Cырсөз",
        register: "Катталуу",
        isRegistered: "Аккаунтуңуз барбы?",
        continueWith: "менен улантуу",
      },
    },
  },
};

export default translations;

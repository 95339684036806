import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import AppealForm from "../../components/AppealForm/AppealForm";

import "./appeal.scss";

const Appeal = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <section className="appeal">
      <div className="appeal__container">
        <div className="appeal__left">
          <div className="appeal__top">
            <h1 className="appeal__title">{t("appeal.title")}</h1>
            <p className="appeal__desc">{t("appeal.desc")}</p>
          </div>
        </div>

        <div className="appeal__right">
          <Tabs value={selectedTab} onChange={handleChange} centered>
            <Tab label="Выездное оформление" />
            <Tab label="Общие вопросы" />
          </Tabs>

          <Box className="appeal__tabs">
            {selectedTab === 0 && <AppealForm />}
            {selectedTab === 1 && <AppealForm />}
            {selectedTab === 2 && <AppealForm />}
          </Box>
        </div>
      </div>
    </section>
  );
};

export default Appeal;
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { HiOutlineEye } from "react-icons/hi2";
import { ru } from "date-fns/locale";
import axios from "axios";
import "./newsCard.scss";

const NewsCard = ({ newsItem }) => {
  const parsedDate = new Date(newsItem.published_date);
  const formattedDate = format(parsedDate, "d MMMM yyyy года", { locale: ru });
  const navigate = useNavigate();

  const selectedLanguage = localStorage.getItem("selectedLanguage") || "kg";
  const title =
    selectedLanguage === "ru" ? newsItem.title_ru : newsItem.title_ky;
  const wordsInTitle = title.split(" ");
  const trimmedTitle = wordsInTitle.slice(0, 10).join(" ");
  const displayTitle =
    wordsInTitle.length > 10 ? `${trimmedTitle}...` : trimmedTitle;

  const firstImage =
    newsItem.images.length > 0 ? newsItem.images[0].image : null;

  const handleClick = async () => {
    try {
      await axios.get(`https://backend.tsvs.kg:5543/api/news/${newsItem.id}/view`);
      navigate(`/news/${newsItem.id}`);
    } catch (error) {
      navigate(`/news/${newsItem.id}`);
    }
  };

  return (
    <div className="news__card" onClick={handleClick}>
      <div className="news__card-img">
        {firstImage && (
          <img
            className="news__img"
            src={`https://backend.tsvs.kg:5543${firstImage}`}
            alt={`News: ${title}`}
          />
        )}
      </div>
      <div className="news__card-info">
        <div className="news__card-date">
          <p className="day">{formattedDate && <span>{formattedDate}</span>}</p>
          <div className="news__card-views">
            {newsItem.viewed}
            <HiOutlineEye />
          </div>
        </div>
        <h3>
          <Link
            style={{ textDecoration: "none" }}
            className="news__card-title"
            to={`/news/${newsItem.id}`}
            onClick={(e) => e.preventDefault()} 
          >
            {displayTitle}
          </Link>
        </h3>
      </div>
    </div>
  );
};

export default NewsCard;

import './tsPageContacts.scss'
import { useTranslation } from 'react-i18next';

const TsPageContacts = () => {

  const {t} = useTranslation();

  return (
    <div className='ts__contacts'>
      <h2 className='ts__contacts__title'>{t("contact")}</h2>
      <div className="ts__contacts__card">
        <p className='ts__nubmer'>
        {t("ts")}:
        +996(312)-63-17-37
        +996(312)-63-17-14
        </p> 
        <p className='ts__email'>
          Почта:  ts.tsvs.gov.kg
        </p>
      </div>
    </div>
  )
}

export default TsPageContacts;
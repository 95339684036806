import { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import he from "he";

const useContacts = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContacts = () => {
      fetch("https://backend.tsvs.kg:5543/api/about/contact")
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch contacts");
          }
          return response.json();
        })
        .then((data) => {
          const sanitizedData = data.map((item) => ({
            ...item,
            title: item.title ? DOMPurify.sanitize(he.decode(item.title)) : "Н/Д",
            phone: item.phone ? DOMPurify.sanitize(he.decode(item.phone)) : "Н/Д",
            address: item.address
              ? DOMPurify.sanitize(he.decode(item.address), { USE_PROFILES: { html: true } })
              : "Н/Д",
            time_job: item.time_job
              ? DOMPurify.sanitize(he.decode(item.time_job), { USE_PROFILES: { html: true } })
              : "Н/Д",
          }));
          setContacts(sanitizedData);
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    };

    fetchContacts();
  }, []);

  return { contacts, loading, error };
};

export default useContacts;

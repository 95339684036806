import React from "react";
import "./pricingTable.scss";
import VehicleOwnRegister from "./VehicleOwnRegsiter/VehicleOwnRegister";
import TractorOwnRegister from "./TractorOwnRegister/TractorOwnRegister";
import LicenseOwnRegister from "./LicenseOwnRegister/LicenseOwnRegister";
import TractorLicenseOwnRegister from "./TractorLicenseOwnRegister/TractorLicenseOwnRegister";
import LicenseOwnConfirm from "./LicenseOwnConfirm/LicenseOwnConfirm";
import { useTranslation } from "react-i18next";

const PricingTable = () => {

  const {t} = useTranslation();

  return (
    <section className="information">
      <div className="information__container">
        <h1 className="information__title">
          {t("information.title")}
        </h1>
        <VehicleOwnRegister />

        <TractorOwnRegister />

        <LicenseOwnRegister />

        <TractorLicenseOwnRegister />

        <LicenseOwnConfirm />
      </div>
    </section>
  );
};

export default PricingTable;

import React, { useState } from "react";
import "./faq.scss";
import { useTranslation } from "react-i18next";
import TsQuestions from "../../components/TsQuestions/TsQuestions";
import VsQuestions from "../../components/VsQuestions/VsQuestions";
import { Box, IconButton } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CustomTabs from '../../components/CustomTabs/CustomTabs';

const Faq = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePreviousTab = () => {
    setValue((prevValue) => (prevValue > 0 ? prevValue - 1 : prevValue));
  };

  const handleNextTab = () => {
    setValue((prevValue) => (prevValue < 1 ? prevValue + 1 : prevValue));
  };

  return (
    <section className="faq">
      <div className="faq__container">
        <div className="faq__top">
          <h2 className="faq__title">{t("faq.title")}</h2>
          <div className="faq__tabs">
            <div className="faq__icons">
              <IconButton onClick={handlePreviousTab} disabled={value === 0}>
                <ChevronLeftIcon />
              </IconButton>
              <IconButton onClick={handleNextTab} disabled={value === 1}>
                <ChevronRightIcon />
              </IconButton>
            </div>

            <CustomTabs
              className="faq__tabs-container"
              value={value}
              onChange={handleChange}
              tabLabels={[t("faq.driver"), t("faq.transport")]}
            />
          </div>
        </div>
        <div className="faq__content">
          {value === 0 && (
            <Box>
              <VsQuestions />
            </Box>
          )}
          {value === 1 && (
            <Box>
              <TsQuestions />
            </Box>
          )}
        </div>
      </div>
    </section>
  );
};

export default Faq;

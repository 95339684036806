import './licenseDocuments.scss';
import { useState, useEffect } from 'react';
import axios from 'axios';
import pdf from '../../../assets/icon/pdf.png';

const LicenseDocuments = () => {
  const [buttons, setButtons] = useState([]);

  useEffect(() => {
    const getButtons = async () => {
      try {
        const response = await axios.get("https://backend.tsvs.kg:5543/api/lc/sample");
        setButtons(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    getButtons();
  }, []);

  return (
    <div className='license__docs__container'>
      {buttons.map((button) => (
        <a 
          key={button.id} 
          href={`https://backend.tsvs.kg:5543${button.file}`} 
          className='license__docs' 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <img src={pdf} alt="DOC" className='license__docs__icon' />
          {button.title}
        </a>
      ))}
    </div>
  );
};

export default LicenseDocuments;

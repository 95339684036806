import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./header.scss";
import logo from "../../assets/img/v3.png";
import BurgerMenu from "../../components/BurgerMenu/BurgerMenu";
import HeaderNav from "../../components/HeaderNav/HeaderNav";
import { useTranslation } from "react-i18next";

const Header = () => {
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const {t} = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`header ${
        scrolled || location.pathname !== "/" ? "scrolled" : ""
      }`}
    >
      <div className="header__container-logo">
        <img onClick={() => navigate('/')} src='https://upload.wikimedia.org/wikipedia/commons/thumb/f/f1/Emblem_of_Kyrgyzstan.svg/200px-Emblem_of_Kyrgyzstan.svg.png' alt="Logo" className="header__container-img" />
        <p className="header__container-head">{t("title")}</p>
      </div>

      <HeaderNav />
      <BurgerMenu />
    </header>
  );
};

export default Header;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* aboutUs.scss */
.MainTabsContainer {
  display: flex;
  position: relative;
  justify-content: center;
  margin: 120px 0;
}
@media (max-width: 768px) {
  .MainTabsContainer {
    flex-direction: column;
  }
}

.AboutCompany {
  position: relative;
  align-items: center;
  justify-content: center;
}
.AboutCompany header {
  margin: 10px;
}

.About {
  display: flex;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
@media (max-width: 768px) {
  .About {
    flex-direction: column;
  }
}

.search__top {
  margin: 10px 0;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
}

.search__select {
  display: flex;
  gap: 15px;
}

.search__input {
  width: 290px;
  height: 45px;
  border-radius: 5px;
  outline: none;
  padding: 10px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 450px) {
  .tabs {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/AboutUs/aboutUs.scss"],"names":[],"mappings":"AAAA,iBAAA;AAEA;EACE,aAAA;EACA,kBAAA;EACA,uBAAA;EACA,eAAA;AAAF;AAEE;EANF;IAOI,sBAAA;EACF;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,YAAA;AACJ;;AAGA;EACE,aAAA;EACA,kBAAA;EACA,uBAAA;EACA,eAAA;EACA,SAAA;AAAF;AAEE;EAPF;IAQI,sBAAA;EACF;AACF;;AAEA;EACE,cAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;EACA,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,YAAA;EACA,wCAAA;AACF;;AAIA;EACE;IACE,sBAAA;EADF;AACF","sourcesContent":["/* aboutUs.scss */\n\n.MainTabsContainer {\n  display: flex;\n  position: relative;\n  justify-content: center;\n  margin: 120px 0;\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n  }\n}\n\n.AboutCompany {\n  position: relative;\n  align-items: center;\n  justify-content: center;\n\n  & header {\n    margin: 10px;\n  }\n}\n\n.About {\n  display: flex;\n  position: relative;\n  justify-content: center;\n  flex-wrap: wrap;\n  gap: 20px;\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n  }\n}\n\n.search__top {\n  margin: 10px 0;\n  display: flex;\n  gap: 15px;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.search__select {\n  display: flex;\n  gap: 15px;\n}\n\n.search__input {\n  width: 290px;\n  height: 45px;\n  border-radius: 5px;\n  outline: none;\n  padding: 10px;\n  border: none;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n\n\n@media (max-width: 450px) {\n  .tabs {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

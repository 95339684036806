import './tsPage.scss'
import { useState } from 'react';
import LicenseModal from '../License/LicenseModal/LicenseModal';
import LicenseFaq from '../License/LicenseFaq/LicenseFaq';
import TsPageContacts from './TsPageContacts/TsPageContacts.jsx'
import { useTranslation } from 'react-i18next';

const TsPage = () => {
  const [activeModal, setActiveModal] = useState(null);
  const {t} = useTranslation()
  const openModal = (index) => setActiveModal(index);
  const closeModal = () => setActiveModal(null);

  const onClick = () => {
    window.location.href = 'https://tsvs.gov.kg/request-for-inspection'; 
  }

  return (
    <section className='ts'>
      <div className="ts__button__container">
        <div className='ts__button-npa' onClick={() => openModal(1)}>
        <h2 className='card__title'>{t("npa")}</h2>
        <button className='more'>{t("more")}</button>
        </div>
        <LicenseModal 
          isOpen={activeModal === 1} 
          onClose={closeModal} 
          apiUrl='https://backend.tsvs.kg:5543/api/ts/npa'  
        />

        <div onClick={onClick} className='ts__button-osmotr'>
          <h2 className='card__title'>{t("service.inspection")}</h2>
          <button className='more'>{t("more")}</button>
        </div>
      </div>

      <LicenseFaq faqApiUrl="https://backend.tsvs.kg:5543/api/ts/faq-ts"/>
      <TsPageContacts/>
    </section>
  );
}

export default TsPage;

import { useTranslation } from "react-i18next";
import useFetchFeatures from "../../hooks/useFetchFeatures";
import "./homeFeatures.scss";

const HomeFeatures = () => {
  const { t } = useTranslation();
  const { features, loading, error } = useFetchFeatures();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading features</div>;

  return (
    <div className="wrapper">
      <div className="wrapper__container">
        <div className="wrapper__right">  
          <div className="wrapper__inner">
             {features.map((feature, index) => (  
                <a href={feature.url_link}>
                  <div
                    className="feature"
                    style={{
                      backgroundImage: `url(${`https://backend.tsvs.kg:5543${feature.image}`})`,
                    }}
                  >
                    <h3 className="feature__title">{feature.title_ru}</h3>
                    <p className="feature__description">
                      {feature.descriptions_ru}
                    </p>
                    <div className="feature__dimmer"></div>
                  </div>
                </a>
             ))}       
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFeatures;
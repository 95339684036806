// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notfound {
  width: 100%;
  margin: 150px 0;
}
.notfound__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0;
  gap: 15px;
  text-align: center;
}
.notfound__title {
  font-size: 150px;
}
.notfound__subtitle {
  font-size: 22px;
}
.notfound__btn {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: #4285f4;
  color: #ffffff;
  cursor: pointer;
  font-family: "Onest", sans-serif;
  font-size: 19px;
}`, "",{"version":3,"sources":["webpack://./src/pages/NotFound/notFound.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,eAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,cAAA;EACA,SAAA;EACA,kBAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,YAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,yBAAA;EACA,cAAA;EACA,eAAA;EACA,gCAAA;EACA,eAAA;AAEJ","sourcesContent":[".notfound {\n  width: 100%;\n  margin: 150px 0;\n  &__container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 80px 0;\n    gap: 15px;\n    text-align: center;\n  }\n  &__title {\n    font-size: 150px;\n  }\n  &__subtitle {\n    font-size: 22px;\n  }\n  &__btn {\n    width: 150px;\n    height: 40px;\n    border-radius: 5px;\n    border: none;\n    background-color: #4285f4;\n    color: #ffffff;\n    cursor: pointer;\n    font-family: \"Onest\", sans-serif;\n    font-size: 19px;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

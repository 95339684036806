// хук для получения данных из сервера в секции Information по "Выдача водительского удостоверения на право управления транспортными средствами"

import { useEffect, useState } from "react";
import axios from "axios";


const useLicenseOwnRegisterData = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://backend.tsvs.kg:5543/api/information/issu01"
        );
        setData(response.data);
      } catch (error) {
        
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return { data, loading, error };
};

export default useLicenseOwnRegisterData;

import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    username: "",
    token: localStorage.getItem("accessToken") || "",
  },
  reducers: {
    loginAccount: (state, action) => {
      state.username = action.payload.username || "";
      state.token = action.payload.token || "";
      localStorage.setItem("accessToken", action.payload.token || "");
    },
    logOutAccount: (state) => {
      state.username = "";
      state.token = "";
      localStorage.removeItem("accessToken");
    },
  },
});

export const { loginAccount, logOutAccount } = userSlice.actions;
export default userSlice.reducer;

import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Stack,
  TextField,
} from "@mui/material";
import useDepartmentData from "../../hooks/useDepartmentData";
import { useSelector } from "react-redux";
import axios from "axios";
import CustomSnackBar from "../SnackBar/SnackBar";
import { useTranslation } from "react-i18next";
import RegisterForm from "../RegisterForm/RegisterForm";

const InspectionForm = () => {
  const theme = createTheme({
    typography: {
      fontFamily: "Onest, sans-serif",
    },
  });

  const [selectedCityId, setSelectedCityId] = useState("");
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { t } = useTranslation();

  const department = useDepartmentData();
  const user = useSelector((state) => state.user.username);
  const token = useSelector((state) => state.user.token);

  const [formData, setFormData] = useState({
    category_id: { selectedCityId },
    nearest_department_id: { selectedDepartmentId },
    fullName: "",
    phoneNumber: "",
    email: "",
    carNumber: "",
    createdDate: new Date().toISOString(),
    isActive: true,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let transformedValue = value;
    if (name === "carNumber") {
      transformedValue = value.toUpperCase();
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: transformedValue,
    }));
  };

  const handleRegisterForm = () => {
    setShowRegisterForm(true);
  };

  const handleCloseRegisterForm = () => {
    setShowRegisterForm(false);
  };

  const handleDepartmentChange = (event) => {
    setSelectedDepartmentId(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const requestData = {
      category_id: selectedCityId,
      nearest_department_id: selectedDepartmentId,
      full_name: formData.fullName,
      phone_number: formData.phoneNumber,
      email: formData.email,
      car_number: formData.carNumber,
      created_date: formData.createdDate,
      is_active: formData.isActive,
    };
  
    console.log("Request data:", requestData);
  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  
    try {
      const response = await axios.post(
        "http://127.0.0.1:8000/api/online/create_appeal",
        requestData,
        config
      );
  
      console.log("Response:", response.data);
  
      setSelectedCityId("");
      setSelectedDepartmentId("");
      setFormData({
        nearest_department_id: "",
        fullName: "",
        phoneNumber: "",
        email: "",
        carNumber: "",
        createdDate: new Date().toISOString(),
        isActive: true,
      });
      setSuccessMessage(response.data.message);
      setSuccessSnackbarOpen(true);
    } catch (error) {
      console.error("Error:", error.response ? error.response.data : error.message);
    }
  };
  const handleSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleSubmit} className="inspection__form">
        {showRegisterForm && <RegisterForm onClose={handleCloseRegisterForm} />}
        <h1>Осмотр транспортного средства</h1>
        <p className="form__input">
          Заполните форму ниже для регситрации вашей заявки на осмотр
        </p>
        <Stack>
          <FormControl variant="outlined" sx={{ minWidth: 120 }}>
            <label id="department-label"> {t("service.department")}</label>

            <MuiSelect
              labelId="department-label"
              id="department-select"
              value={selectedDepartmentId}
              onChange={handleDepartmentChange}
              placeholder="Ваше отделение"
            >
              {department.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.title}
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl>
        </Stack>
        <div className="input">
          <label>{t("service.fullName")}</label>
          <TextField
            className="inspection__input"
            placeholder={t("service.fullName")}
            variant="outlined"
            name="fullName"
            value={formData.fullName}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="input">
          <label>{t("service.phoneNumber")}</label>
          <TextField
            className="inspection__input"
            placeholder="+(996)"
            variant="outlined"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="input">
          <label>{t("service.email")}</label>
          <TextField
            className="inspection__input"
            placeholder={t("service.email")}
            variant="outlined"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            type="email"
          />
        </div>
        <div className="input">
          <label>{t("service.carNumber")}</label>
          <TextField
            className="inspection__input"
            variant="outlined"
            name="carNumber"
            placeholder={t("service.carNumber")}
            value={formData.carNumber}
            onChange={handleInputChange}
            required
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
        </div>
        <CustomSnackBar
          open={successSnackbarOpen}
          message={successMessage}
          onClose={handleSnackbarClose}
        />
        {user ? (
          <button type="submit" className="inspection__btn">
            {t("service.send")}
          </button>
        ) : (
          <button onClick={handleRegisterForm} className="inspection__btn">
            {t("service.register")}
          </button>
        )}
      </form>
    </ThemeProvider>
  );
};

export default InspectionForm;
import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../pages/Home/home.scss";
import { useTranslation } from "react-i18next";

import NewsCard from "../../pages/News/NewsCard/NewsCard";

const HomeNews = () => {
  const [news, setNews] = useState([]);
  const [newsLimit, setNewsLimit] = useState(6);

  const { t } = useTranslation();

  useEffect(() => {
    const getNews = async () => {
      try {
        const res = await axios.get(
          `https://backend.tsvs.kg:5543/api/news/?_limit=` + newsLimit
        );
        setNews(res.data);
      } catch (err) {}
    };

    getNews();
  }, [newsLimit]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 2380) {
        setNewsLimit(6);
      } else {
        setNewsLimit(8);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="home__news">
      <h1 className="home__news-title">{t("homeNewsTitle")}</h1>

      {news.length > 0 && (
        <div className="home__news-container">
          {news.slice(0, newsLimit).map((item, index) => (
            <NewsCard key={item.id} newsItem={item} />
          ))}
        </div>
      )}
    </section>
  );
};

export default HomeNews;

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../layout/Header/header.scss";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import { useTranslation } from "react-i18next";
import { MdKeyboardArrowDown } from "react-icons/md";
import { HiOutlineUser } from "react-icons/hi2";
import RegisterForm from "../RegisterForm/RegisterForm";
import { GrLanguage } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { logOutAccount } from "../../redux/reducers/userSlice";
import CustomSnackBar from "../SnackBar/SnackBar";
import LoginForm from "../LoginForm/LoginForm";

const HeaderNav = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [showForm, setShowForm] = useState(false);
  const user = useSelector((state) => state.user.username);
  const dispatch = useDispatch();
  const isLoggedIn = !!user;
  const [snackbarShown, setSnackbarShown] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const snackbarWasShown = localStorage.getItem("snackbarShown");
    if (isLoggedIn && !snackbarWasShown) {
      setSnackbarShown(true);
      localStorage.setItem("snackbarShown", "true");
    }

    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoggedIn]);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleLogout = () => {
    dispatch(logOutAccount());
    localStorage.removeItem("snackbarShown");
  };

  const closeSnackbar = () => {
    setSnackbarShown(false);
  };

  return (
    <>
      <nav className="header__navigation">
        <ul className="header__navigation-list">
          {/* <li className={`header__navigation-item ${location.pathname === "/" ? "active" : ""}`}>
            <Link to="/">{t("home")}</Link>
          </li> */}
          <li className={`header__navigation-item ${location.pathname === "/about" ? "active" : ""}`}>
            <Link to="/about">{t("about")}</Link>
          </li>
          <li className={`header__navigation-item ${location.pathname === "/news" ? "active" : ""}`}>
            <Link to="/news">{t("news")}</Link>
          </li>
          <li className={`header__navigation-item ${location.pathname === "/license" ? "active" : ""}`}>
            <Link to="/license"> {t("info.license")}</Link>
          </li>
          <li className={`header__navigation-item ${location.pathname === "/news" ? "active" : ""}`}>
            <Link to="/vs">{t("vs")}</Link>
          </li>
          <li className={`header__navigation-item ${location.pathname === "/news" ? "active" : ""}`}>
            <Link to="/ts">{t("ts")}</Link>
          </li>
          {/* <li className={`header__navigation-item ${location.pathname === "/information" ? "active" : ""}`}>
            <Link to="/information">{t("info")}</Link>
          </li> */}
          {/* <li className={`header__navigation-item ${scrolled ? "scrolled" : ""}`}>
            {t("info.main")} <MdKeyboardArrowDown />
            <ul
              className={`header__dropdown ${
                scrolled || location.pathname !== "/" ? "scrolled" : ""
              }`}
            >
              <li>
                <Link to="/pricing">{t("info.price")}</Link>
              </li>
              <li>
                <Link to="/license">
                  {t("info.license")}
                </Link>
              </li>
            </ul>
          </li> */}
          {/* <li className={`header__navigation-item ${scrolled ? "scrolled" : ""}`}>
            Водительский состав <MdKeyboardArrowDown />
            <ul
              className={`header__dropdown ${
                scrolled || location.pathname !== "/" ? "scrolled" : ""
              }`}
            >
              <li>
                <Link to="/calculator">{t("service.calculator")}</Link>
              </li>
              <li>
                <Link to="/request-for-inspection">
                  {t("service.inspection")}
                </Link>
              </li>
              <li>
                <Link to="https://kattoo-prava.srs.kg/">
                  {t("studentRegister")}
                </Link>
              </li>
            </ul>
          </li> */}
          {/* <li className={`header__navigation-item ${location.pathname === "/appeal" ? "active" : ""}`}>
            <Link to="/appeal">{t("appeal.title")}</Link>
          </li> */}

<li className={`header__navigation-item ${location.pathname === "/camera" ? "active" : ""}`}>
            <Link to="/camera">{t("camera")}</Link>
          </li>


          {/* <li className={`header__navigation-item ${location.pathname === "/jobs" ? "active" : ""}`}>
            <Link to="/jobs">{t("jobs")}</Link>
          </li>
          <li className={`header__navigation-item ${location.pathname === "/camera" ? "active" : ""}`}>
            <Link to="/camera">{t("camera")}</Link>
          </li> */}
          <li className="header__navigation-item">
            <GrLanguage />
            <LanguageSelect />
          </li>
          <li className="header__navigation-item">
            {isLoggedIn ? (
              <Link to="/" onClick={handleLogout}>
                {t("exit")}
              </Link>
            ) : (
              <HiOutlineUser className="user__icon" onClick={toggleForm} />
            )}
          </li>
        </ul>
        {snackbarShown && (
          <CustomSnackBar
            open={true}
            message={t("loginSuccessMessage")}
            onClose={closeSnackbar}
          />
        )}
      </nav>
      {showForm && <LoginForm onClose={toggleForm} />}
    </>
  );
};

export default HeaderNav;

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import History from "./AboutDetails/History/History";
import Management from "./AboutDetails/Management/Management";
import Contacts from "./AboutDetails/Contacts/Сontacts";
import { useTranslation } from "react-i18next";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import "./aboutUs.scss";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AboutUs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { t } = useTranslation();

  return (
    <div className="MainTabsContainer">
      <div className="container">
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              position: "relative",
              justifyContent: "center",
            }}
            className="tabs__container"
          >
            <CustomTabs
              value={value}
              onChange={handleChange}
              tabLabels={[t("history"), t("management"), t("contact")]}
            />
          </Box>
          <CustomTabPanel value={value} index={0}>
            <History />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Management />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Contacts />
          </CustomTabPanel>
        </Box>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";
import axios from "axios";
import "../../pages/Appeal/appeal.scss";

const AppealForm = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    middle_name: "",
    email: "",
    phone_number: "",
    whatsapp: "",
    telegram: "",
    text: "",
    file: "",
    regions_id: 0,
    address: "",
  });

  const [departments, setDepartments] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");

  const token = useSelector((state) => state.user.token); 

  useEffect(() => {
    fetch("http://127.0.0.1:8000/api/online/departments")
      .then((response) => response.json())
      .then((data) => setDepartments(data))
      .catch((error) => console.error("Error fetching departments:", error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setForm({
      ...form,
      file: e.target.files[0],
    });
  };

  const handleDepartmentChange = (e) => {
    setSelectedDepartmentId(e.target.value);
    setForm({
      ...form,
      regions_id: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      ...form,
      regions_id: selectedDepartmentId,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${token}`, 
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.post(
        "http://127.0.0.1:8000/api/online/statement",
        requestData,
        config
      );

      console.log("Response:", response.data);

      setForm({
        first_name: "",
        last_name: "",
        middle_name: "",
        email: "",
        phone_number: "",
        whatsapp: "",
        telegram: "",
        text: "",
        file: "",
        regions_id: 0,
        address: "",
      });
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <form className="appeal__form" onSubmit={handleSubmit}>
      <div className="appeal__fullname">
        <div className="appeal__input">
          <label>{t("user.name")}</label>
          <TextField
            name="first_name"
            placeholder={t("user.name")}
            value={form.first_name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </div>
        <div className="appeal__input">
          <label>{t("user.surname")}</label>
          <TextField
            name="last_name"
            placeholder={t("user.surname")}
            value={form.last_name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </div>
        <div className="appeal__input">
          <label>{t("user.patronymic")}</label>
          <TextField
            name="middle_name"
            placeholder={t("user.patronymic")}
            value={form.middle_name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </div>
      </div>
      <div className="appeal__input">
        <label>{t("user.email")}</label>
        <TextField
          type="email"
          placeholder={t("user.email")}
          name="email"
          value={form.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      </div>
      <div className="appeal__input">
        <label>{t("user.phone")}</label>
        <InputMask
          mask="(999) 99-99-99"
          value={form.phone_number}
          onChange={handleChange}
        >
          {(inputProps) => (
            <TextField
              {...inputProps}
              name="phone_number"
              placeholder="(000) 00-00-00"
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+996</InputAdornment>
                ),
              }}
            />
          )}
        </InputMask>
      </div>
      <div className="appeal__input">
        <label>WhatsApp</label>
        <TextField
          name="whatsapp"
          placeholder="Whatsapp"
          value={form.whatsapp}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      </div>
      <div className="appeal__input">
        <label>Telegram</label>
        <TextField
          name="telegram"
          placeholder="@telegram"
          value={form.telegram}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      </div>
      <div className="appeal__input">
        <label>{t("user.appealText")}</label>
        <TextField
          name="text"
          placeholder="Ваше сообщение"
          value={form.text}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
      </div>
      <div className="appeal__file">
        <InputLabel>Файл</InputLabel>
         <input
          type="file"
          name="file"
          onChange={handleFileChange}
          style={{ marginBottom: "16px" }}
        />
      </div>
      <div className="appeal__input">
        <label>Отдел</label>
        <Select
          labelId="department-label"
          id="department-select"
          value={selectedDepartmentId}
          onChange={handleDepartmentChange}
          fullWidth
          margin="normal"
          placeholder="Ваше отделение"
        >
          {departments.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="appeal__input">
        <label>Адрес</label>
        <TextField
          name="address"
          placeholder="Адрес"
          value={form.address}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      </div>
      <div className="appeal__btn">
        <Button type="submit" variant="contained" color="primary">
          Отправить
        </Button>
      </div>
    </form>
  );
};

export default AppealForm;

import React from "react";
import useVehicleOwnRegisterData from "../../../hooks/InformationHooks/useVehicleOwnRegsiterData";

const VehicleOwnRegister = () => {
  const { data, loading, error } = useVehicleOwnRegisterData();

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>Error fetching data: {error}</div>;
  }

  return (
    <div className="information__table">
      <h3 className="information__table-title">
        Регистрация (перерегистрация) прав собственности на автомототранспортные
        средства, выдачей правоудостоверяющих документов, регистрационных
        номерных знаков
      </h3>

      <table>
        <thead>
          <tr>
            <th>№</th>
            <th>Наименование услуги</th>
            <th>Стоимость (сом)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.number}>
              <td className="number">{item.number}</td>
              <td className="middle">{item.title}</td>
              <td className="price">{item.price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VehicleOwnRegister;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news {
  width: 100%;
  margin-top: 120px;
}
.news:before {
  content: "";
  position: absolute;
  bottom: 10px;
  left: -27px;
  width: 20px;
  height: 2px;
  background-color: #538ffc;
}
.news__container {
  display: grid;
  grid-template-columns: 28% 28% 28%;
  width: 100%;
  gap: 25px;
  justify-content: center;
}

.MuiStack-root {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1400px) {
  .news__container {
    grid-template-columns: 45% 40%;
  }
}
@media (max-width: 1050px) {
  .news__container {
    grid-template-columns: 0fr;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/News/news.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;AACF;AAAE;EACE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAEJ;AACE;EACE,aAAA;EACA,kCAAA;EACA,WAAA;EACA,SAAA;EACA,uBAAA;AACJ;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF;;AAGA;EACE;IACE,8BAAA;EAAF;AACF;AAGA;EACE;IACE,0BAAA;EADF;AACF","sourcesContent":[".news {\n  width: 100%;\n  margin-top: 120px;\n  &:before {\n    content: \"\";\n    position: absolute;\n    bottom: 10px;\n    left: -27px;\n    width: 20px;\n    height: 2px;\n    background-color: #538ffc;\n  }\n\n  &__container {\n    display: grid;\n    grid-template-columns: 28% 28% 28%;\n    width: 100%;\n    gap: 25px;\n    justify-content: center;\n  }\n}\n\n.MuiStack-root {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@media (max-width: 1400px) {\n  .news__container{\n    grid-template-columns: 45% 40%;\n  }\n}\n\n@media (max-width: 1050px) {\n  .news__container{\n    grid-template-columns: 0fr;\n  }\n}\n\n// section {\n// \tdisplay: flex;\n// \tjustify-content: center;\n// \talign-items: center;\n// \tflex-direction: column;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

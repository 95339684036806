import './vsPage.scss';
import { useState } from 'react';
import LicenseModal from '../License/LicenseModal/LicenseModal';
import LicenseFaq from '../License/LicenseFaq/LicenseFaq';
import VsPageContacts from './VsPageContacts/VsPageContacts';
import { useTranslation } from 'react-i18next';

const Vs = () => {
  const [activeModal, setActiveModal] = useState(null);
  const { t } = useTranslation();
  const openModal = (index) => setActiveModal(index);
  const closeModal = () => setActiveModal(null);

  const onClick = () => {
    window.location.href = 'https://kattoo-prava.srs.kg/'; 
  }

  return (
    <section className='vs'>
      <div className="vs__button__container">
        <div className='vs__button-npa' onClick={() => openModal(1)}>
        <h2 className='card__title'>{t("npa")}</h2>
        <button className='more'>{t("more")}</button>
        </div>
        <LicenseModal 
          isOpen={activeModal === 1} 
          onClose={closeModal} 
          apiUrl='https://backend.tsvs.kg:5543/api/lc/npa' 
        />

        <div onClick={onClick} className='vs__button-register'>
          <h2 className='card__title'>{t("studentRegister")}</h2>
          <button className='more'>{t("more")}</button>
        </div>
      </div>

      <LicenseFaq faqApiUrl="https://backend.tsvs.kg:5543/api/vs/faq-vs" />
      <VsPageContacts/>
    </section>
  );
}

export default Vs;

import React, { useState, useEffect, useCallback } from "react";
import "./registerForm.scss";
import { AiOutlineClose } from "react-icons/ai";
import LoginForm from "../LoginForm/LoginForm";
import { useForm } from "react-hook-form";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    fontFamily: "Onest, sans-serif",
  },
});

const CustomButton = styled(Button)({
  fontFamily: "Onest, sans-serif",
});

const RegisterForm = ({ onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [isRegistrationSuccess, setIsRegistrationSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 150);

    return () => clearTimeout(timer);
  }, []);

  const handleCloseForm = useCallback(() => {
    onClose();
  }, [onClose]);

  const toggleForm = useCallback(() => {
    setShowLoginForm((prevShowLoginForm) => !prevShowLoginForm);
  }, []);

  const onSubmit = useCallback((data) => {
    axios
      .post(`http://127.0.0.1:8000/api/register/register`, data)
      .then((response) => {
        console.log(response);
        setIsRegistrationSuccess(true);
      })
      .catch((err) => {});
  }, []);

  const handleDialogClose = () => {
    setIsRegistrationSuccess(false);
    onClose();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {showLoginForm ? (
        <LoginForm onClose={onClose} />
      ) : (
        <div className={`overlay ${isVisible ? "show" : ""}`}>
          <form
            className={`register ${isVisible ? "show" : ""}`}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <button className="register__close" onClick={handleCloseForm}>
              <AiOutlineClose />
            </button>
            <p className="register__title">{t("registerTitle")}</p>
            <p className="register__subtitle">
              Создайте новую учетную запись для входа в сайт
            </p>
            <div className="register__container">
              <div className="register__top">
                <div className="register__form">
                  <CustomTextField
                    {...register("first_name", { required: true })}
                    type="text"
                    placeholder={t("user.name")}
                    variant="outlined"
                    error={!!errors.first_name}
                    helperText={errors.first_name ? "*Обязательное поле" : ""}
                    fullWidth
                    required
                  />
                </div>
                <div className="register__form">
                  <CustomTextField
                    {...register("last_name", { required: true })}
                    type="text"
                    placeholder={t("user.surname")}
                    variant="outlined"
                    error={!!errors.last_name}
                    helperText={errors.last_name ? "*Обязательное поле" : ""}
                    fullWidth
                    required
                  />
                </div>
                <div className="register__form">
                  <CustomTextField
                    {...register("email", {
                      required: true,
                      pattern: /^\S+@\S+$/i,
                    })}
                    type="text"
                    placeholder={t("user.email")}
                    variant="outlined"
                    error={!!errors.email}
                    helperText={
                      errors.email ? "*Введите корректный email адрес" : ""
                    }
                    fullWidth
                    required
                  />
                </div>
                <div className="register__form">
                  <CustomTextField
                    {...register("username", { required: true })}
                    type="text"
                    placeholder="Логин"
                    variant="outlined"
                    error={!!errors.username}
                    helperText={errors.username ? "*Обязательное поле" : ""}
                    fullWidth
                    required
                  />
                </div>
                <div className="register__form">
                  <CustomTextField
                    {...register("password", { required: true, minLength: 6 })}
                    type={showPassword ? "text" : "password"}
                    placeholder={t("user.password")}
                    variant="outlined"
                    error={!!errors.password}
                    helperText={
                      errors.password
                        ? "*Пароль должен содержать минимум 6 символов"
                        : ""
                    }
                    fullWidth
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="register__bottom">
                <CustomButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="register__btn"
                >
                  {t("user.register")}
                </CustomButton>
                <p href="#" className="register__link" onClick={toggleForm}>
                  {t("user.isRegistered")}
                </p>
              </div>
            </div>
          </form>
          <Dialog
            open={isRegistrationSuccess}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Регистрация успешно завершена!
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Теперь вы можете войти в свой аккаунт.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                Закрыть
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default RegisterForm;

import React, { useMemo } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import useVsData from "../../hooks/useVsData";

const VsQuestions = () => {
  const { t } = useTranslation();
  const selectedLanguage = useMemo(
    () => localStorage.getItem("selectedLanguage") || "kg",
    []
  );
  const { vsData, loading, error } = useVsData();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading FAQs</div>;
  if (!vsData.length) return null;

  return (
    <div className="accordion__right">
      {vsData.map((item) => (
        <Accordion key={item.id}>
          <AccordionSummary expandIcon={<AddIcon />}>
            <Typography
              variant="body1"
              style={{ fontFamily: "Onest, sans-serif" }}
            >
              {selectedLanguage === "kg" ? item.question_kg : item.question_ru}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              style={{ fontFamily: "Onest, sans-serif" }}
            >
              {selectedLanguage === "kg" ? item.answer_kg : item.answer_ru}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default VsQuestions;

import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const CustomTabs = ({ value, onChange, tabLabels }) => {
  return (
    <Tabs value={value} onChange={onChange}>
      {tabLabels.map((label, index) => (
        <Tab key={index} label={label} />
      ))}
    </Tabs>
  );
};

export default CustomTabs;

export function formatISODate(isoDate) {
    try {
        // Проверяем, что входные данные — это строка
        if (typeof isoDate !== 'string') {
            throw new Error("Дата должна быть строкой в формате ISO 8601.");
        }
        
        // Пробуем создать объект Date
        const date = new Date(isoDate);

        console.log(date)
        
        // Проверяем, корректно ли дата преобразовалась
        if (isNaN(date.getTime())) {
            throw new Error("Неверный формат даты. Ожидается формат ISO 8601 (например, '2023-07-09T18:00:00Z').");
        }

        // Форматируем дату
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        
        return `${day}-${month}-${year}`;
    } catch (error) {
        console.error(error.message, { input: isoDate });
        return null;
    }
}

import React from 'react'
import { Document, Page, PDFViewer, View, Font, StyleSheet, Text , Image} from "@react-pdf/renderer"
import { formatISODate } from '../../../utils/helpers/date-helpers';

Font.register({
    family: 'RobotoSlab',
    src: '/fonts/RobotoSlab-Regular.ttf',
    fontWeight: 400
});
Font.register({
    family: 'RobotoSlab',
    src: '/fonts/RobotoSlab-ExtraLight.ttf',
    fontWeight: 200
});
Font.register({
    family: 'RobotoSlab',
    src: '/fonts/RobotoSlab-ExtraBold.ttf',
    fontWeight: 800
});
Font.register({
    family: 'RobotoSlab',
    src: '/fonts/RobotoSlab-Light.ttf',
    fontWeight: 300
});
Font.register({
    family: 'RobotoSlab',
    src: '/fonts/RobotoSlab-Medium.ttf',
    fontWeight: 500
});
Font.register({
    family: 'RobotoSlab',
    src: '/fonts/RobotoSlab-SemiBold.ttf',
    fontWeight: 600
});
Font.register({
    family: 'RobotoSlab',
    src: '/fonts/RobotoSlab-Bold.ttf',
    fontWeight: 700
});
Font.register({
    family: 'RobotoSlab',
    src: '/fonts/RobotoSlab-Thin.ttf',
    fontWeight: 100
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 55,
        position: 'relative',
    },
    gerb: {
        width: 70,
        marginRight: 50,
        marginLeft: 50,
    },
    title: {
        fontFamily: 'RobotoSlab',
        fontSize: 12,
        fontWeight: 800,
        width: 260,
        margin: 10,
    },
    subtitle: {
        fontFamily: 'RobotoSlab',
        fontSize: 20,
        fontWeight: 700,
    },
    rowTitle: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
        justifyContent: 'center',
    },
    row: {
        flexDirection: 'column',
        marginVertical: 10,
    },
    background: {
        position: 'absolute',
        top: 230,
        left: 90,
        width: '70%',
        opacity: 0.5,
        zIndex: '-1'
    },
    separator1: {
        height: 2,
        backgroundColor: 'black',
        marginBottom: 5,
        width: '100%',
    },
    separator2: {
        height: 2,
        backgroundColor: 'black',
        width: '100%',
    },
    description: {
        fontFamily: 'RobotoSlab',
        fontSize: 10,
        fontWeight: 500,
    },
    text: {
        fontFamily: 'RobotoSlab',
        fontSize: 12,
        marginTop: 5,
        fontWeight: 400,
    },
    qrCode: {
        width: 50
    },
    rowQr: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 10,
    }
});




const LicensePDF = ({licenseData, qrCodeUrl, qrCodeUrlMinUst}) => {     

    return (
        <Document>
            <Page style={styles.body}>
                <View style={styles.rowTitle}>
                    <Image style={styles.gerb} src={'/gerb.png'}/>
                    <Text style={styles.title}>
                        Государственное агентство по регистрации
                        транспортных средств и водительского
                        состава при Кабинете Министров Кыргызской
                        Республики
                    </Text>
                </View>
                <View style={styles.separator1}></View>
                <View style={styles.rowTitle}>
                    <Text style={styles.subtitle}>
                        ЛИЦЕНЗИЯ
                    </Text>
                </View>
                <View style={styles.separator2}></View>
                <View style={styles.row}>
                    <Text style={styles.description}>
                        Наименование юридического лица:
                    </Text>
                    <Text style={styles.text}>
                        {licenseData.name_entity_ru}
                    </Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.description}>
                    Организационно-правовая форма:
                    </Text>
                    <Text style={styles.text}>
                        {licenseData.status_code}
                    </Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.description}>
                        Лицензируемый вид деятельности:
                    </Text>
                    <Text style={styles.text}>
                        Образовательная деятельность по подготовке и переподготовке водителей транспортных средств
                    </Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.description}>
                        Срок действия лицензии:
                    </Text>
                    <Text style={styles.text}>
                        {licenseData.re_registration_ru}
                    </Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.description}>
                        Дата выдачи:
                    </Text>
                    <Text style={styles.text}>
                        {formatISODate(licenseData.data_license)}
                    </Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.description}>
                        Регистрационный номер лицензии:
                    </Text>
                    <Text style={styles.text}>
                        {licenseData.number_register_ru}
                    </Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.description}>
                        Идентификационный номер налогоплательщика:
                    </Text>
                    <Text style={styles.text}>
                        {licenseData.tax_name_ru}
                    </Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.description}>
                        Основание:
                    </Text>
                    <Text style={styles.text}>
                        {licenseData.issuing_license_ru}
                    </Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.description}>
                        Номер бланка:
                    </Text>
                    <Text style={styles.text}>
                        {licenseData.form_number}
                    </Text>
                </View>
                <View style={styles.rowQr}>
                    <Image style={styles.qrCode} src={qrCodeUrl} />
                    <Image style={styles.qrCode} src={qrCodeUrlMinUst} />
                </View>
                <Image style={styles.background} src={'/bg-gerb.png'} />
                
            </Page>
        </Document>
    )
}

export default LicensePDF;
import React, { useState, useEffect, useCallback } from "react";
import { IoCloseOutline } from "react-icons/io5";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginAccount } from "../../redux/reducers/userSlice";
import "./loginForm.scss";
import { useTranslation } from "react-i18next";
import RegisterForm from "../RegisterForm/RegisterForm";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Button from "@mui/material/Button";

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    fontFamily: "Onest, sans-serif",
  },
});

const CustomButton = styled(Button)({
  fontFamily: "Onest, sans-serif",
});

const LoginForm = ({ onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [loginData, setLoginData] = useState({ username: "", password: "" });
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 150);

    return () => clearTimeout(timer);
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  const handleLogin = useCallback(async () => {
    try {
      const updatedLoginData = { ...loginData };
      const response = await axios.post(
        "http://127.0.0.1:8000/api/login/login",
        updatedLoginData
      );
      console.log("Login response:", response.data);

      if (response.data.message === "Вход выполнен успешно") {
        const { access_token } = response.data.token;
        localStorage.setItem("accessToken", access_token);  
        dispatch(loginAccount({ username: updatedLoginData.username, token: access_token }));
        onClose();

        console.log("Login success:");
      } else {
        console.error("Login failed:");
        alert("Ошибка входа. Пожалуйста, проверьте введенные данные.");
      }
    } catch (error) {
      alert("Ошибка входа. Пожалуйста, проверьте введенные данные.");
    } finally {
      setLoginData({ username: "", password: "" });
    }
  }, [dispatch, loginData, onClose]);

  const toggleForm = useCallback(() => {
    setShowRegisterForm((prevShowRegisterForm) => !prevShowRegisterForm);
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {showRegisterForm ? (
        <RegisterForm onClose={onClose} />
      ) : (
        <div className={`overlay ${isVisible ? "show" : ""}`}>
          <div className={`login ${isVisible ? "show" : ""}`}>
            <IoCloseOutline className="login__close" onClick={onClose} />
            <p className="login__title">{t("login")}</p>
            <p className="login__subtitle">Войти в свою учетную запись</p>
            <div className="login__container">
              <div className="login__top">
                <div className="login__form">
                  <CustomTextField
                    required
                    type="text"
                    className="login__input"
                    placeholder="Логин"
                    name="username"
                    value={loginData.username}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <div className="login__form">
                  <CustomTextField
                    required
                    type={showPassword ? "text" : "password"}
                    className="login__input"
                    placeholder={t("user.password")}
                    name="password"
                    value={loginData.password}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="lgn__btn">
                <CustomButton
                  className="login__btn"
                  onClick={handleLogin}
                  variant="contained"
                  color="primary"
                >
                  {t("login")}
                </CustomButton>
              </div>
              <CustomButton
                className="login-create-btn"
                onClick={toggleForm}
                variant="text"
              >
                Нет аккаунта?
              </CustomButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginForm;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.license__contacts {
  display: flex;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 76%;
  margin: 30px 0px;
}
.license__contacts__card {
  font-size: 17px;
  display: flex;
  gap: 20px;
  margin: 20px 0px;
}

.license__number {
  text-decoration: underline;
}

.license__email {
  text-decoration: underline;
}

@media (max-width: 450px) {
  .license__contacts__card {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/License/LicenseContacts/licenseContacts.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,gBAAA;EACA,uBAAA;EACA,2BAAA;EACA,sBAAA;EACA,UAAA;EACA,gBAAA;AACJ;AAAI;EACA,eAAA;EACA,aAAA;EACA,SAAA;EACA,gBAAA;AAEJ;;AAEA;EACI,0BAAA;AACJ;;AACA;EACI,0BAAA;AAEJ;;AACA;EACI;IACI,sBAAA;EAEN;AACF","sourcesContent":[".license__contacts{\n    display: flex;\n    text-align: left;\n    align-items: flex-start;\n    justify-content: flex-start;\n    flex-direction: column;\n    width: 76%;\n    margin: 30px 0px;\n    &__card{\n    font-size: 17px;\n    display: flex;\n    gap: 20px;\n    margin: 20px 0px;\n    }\n}\n\n.license__number{\n    text-decoration: underline;\n}\n.license__email{\n    text-decoration: underline;\n}\n\n@media (max-width: 450px) {\n    .license__contacts__card{\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Подтверждение сведений о транспортных средствах и водительских удостоверениях

import React from "react";
import useLicenseOwnConfirm from "../../../hooks/InformationHooks/useLicenseOwnConfirmData";

const LicenseOwnConfirm = () => {
  const { data, loading, error } = useLicenseOwnConfirm();

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>Error fetching data: {error}</div>;
  }

  return (
    <div className="information__table">
      <h3 className="information__table-title">
        Подтверждение сведений о транспортных средствах и водительских
        удостоверениях
      </h3>
      <table>
        <thead>
          <tr>
            <th>№</th>
            <th>Наименование услуги</th>
            <th>Стоимость (сом)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.number}>
              <td >{item.number}</td>
              <td>{item.title}</td>
              <td>{item.price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LicenseOwnConfirm;

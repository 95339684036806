// Выдача удостоверения тракториста машиниста на право управления самоходными технологическими машинами

import React from "react";
import useTractorLicenseOwnRegisterData from "../../../hooks/InformationHooks/useTractorLicenseOwnRegisterData";

const TractorLicenseOwnRegister = () => {
  const { data, loading, error } = useTractorLicenseOwnRegisterData();

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>Error fetching data: {error}</div>;
  }

  return (
    <div className="information__table">
      <h3 className="information__table-title">
        Выдача удостоверения тракториста машиниста на право управления
        самоходными технологическими машинами
      </h3>
      <table>
        <thead>
          <tr>
            <th>№</th>
            <th>Наименование услуги</th>
            <th>Стоимость (сом)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.number}>
              <td className="number">{item.number}</td>
              <td className="middle">{item.title}</td>
              <td className="price">{item.price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TractorLicenseOwnRegister;

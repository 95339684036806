// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.information {
  margin-top: 80px;
}
.information__container {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 20px 0px;
  flex-direction: column;
}
.information__title {
  text-align: center;
  margin: 20px 0px;
  text-align: left;
  font-size: 22px;
}
.information__table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}
.information__table-title {
  text-align: left;
  padding: 20px 0;
}

table {
  width: 1040px;
}

h1,
h2 {
  color: #333;
}

th,
td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.number {
  width: 38px;
}

.title {
  width: 668px;
}

.price {
  width: 146px;
}

th {
  background-color: #ccc;
}

@media (max-width: 1200px) {
  .information__container {
    max-width: 100%;
  }
  table {
    width: 100%;
  }
  .number {
    width: auto;
  }
  .title {
    width: 668px;
  }
  .price {
    width: 146px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/PricingTable/pricingTable.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAAE;EACE,aAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,iBAAA;EACA,sBAAA;AAEJ;AAAE;EACE,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;AAEJ;AAAE;EACE,WAAA;EACA,yBAAA;EACA,gBAAA;AAEJ;AADI;EACE,gBAAA;EACA,eAAA;AAGN;;AACA;EACE,aAAA;AAEF;;AACA;;EAEE,WAAA;AAEF;;AACA;;EAEE,sBAAA;EACA,YAAA;EACA,gBAAA;AAEF;;AACA;EACE,WAAA;AAEF;;AACA;EACE,YAAA;AAEF;;AACA;EACE,YAAA;AAEF;;AACA;EACE,sBAAA;AAEF;;AACA;EACE;IACE,eAAA;EAEF;EAAA;IACE,WAAA;EAEF;EAAA;IACE,WAAA;EAEF;EAAA;IACE,YAAA;EAEF;EAAA;IACE,YAAA;EAEF;AACF","sourcesContent":[".information {\n  margin-top: 80px;\n  &__container {\n    width: 1000px;\n    margin: 0 auto;\n    display: flex;\n    justify-content: center;\n    padding: 20px 0px;\n    flex-direction: column;\n  }\n  &__title {\n    text-align: center;\n    margin: 20px 0px;\n    text-align: left;\n    font-size: 22px;\n  }\n  &__table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 10px;\n    &-title {\n      text-align: left;\n      padding: 20px 0;\n    }\n  }\n}\ntable {\n  width: 1040px;\n}\n\nh1,\nh2 {\n  color: #333;\n}\n\nth,\ntd {\n  border: 1px solid #ccc;\n  padding: 8px;\n  text-align: left;\n}\n\n.number {\n  width: 38px;\n}\n\n.title {\n  width: 668px;\n}\n\n.price {\n  width: 146px;\n}\n\nth {\n  background-color: #ccc;\n}\n\n@media (max-width: 1200px) {\n  .information__container {\n    max-width: 100%;\n  }\n  table {\n    width: 100%;\n  }\n  .number {\n    width: auto;\n  }\n  .title {\n    width: 668px;\n  }\n  .price {\n    width: 146px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

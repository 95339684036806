import './licenseContacts.scss';
import { useTranslation } from 'react-i18next';

const LicenseContacts = () => {

  const {t} = useTranslation()

  return (
    <div className='license__contacts'>
      <h2 className='license__contacts__title'>{t("contact")}</h2>
      <div className="license__contacts__card">
        <p className='license__nubmer'>
        {t("info.license")}:
        +996(312)-44-71-71
        </p> 
        <p className='license__email'>
          Почта:  lc.tsvs.gov.kg
        </p>
      </div>
    </div>
  )
}

export default LicenseContacts
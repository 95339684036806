// хук для получения данных в разделе Information, в поле "Выдача удостоверения тракториста машиниста на право управления самоходными технологическими машинами"

import { useEffect, useState } from "react";
import axios from "axios";

const useTractorLicenseOwnRegisterData = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://backend.tsvs.kg:5543/api/information/issu02")
                setData(response.data);
            } catch (error) {
              
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [])
    return { data, loading, error };
}

export default useTractorLicenseOwnRegisterData;

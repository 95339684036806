import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTranslation } from'react-i18next';

const CustomSnackBar = ({ open, message, onClose }) => {

  const {t} = useTranslation();

  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={onClose}>
      <Alert
        onClose={onClose}
        severity="success"
        variant="filled"
        sx={{ width: '100%' }}
      >
        {t("message")}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackBar;
